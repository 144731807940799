import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { Grid } from 'layout/grid';
import { StyledCell } from 'layout/cell/cell.styles';
import { media } from 'styles/media';

export const StyledCardContainer = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.lightGrey};
    padding: ${theme.spacing.s3};

    ${media.lessThan('sm')`
      padding: ${theme.spacing.s2};
    `}

    ${media.lessThan('sm')`
      padding: ${theme.spacing.s1};
    `}
  `}
`;

export const StyledCardContentGrid = styled(Grid)`
  ${({ theme }) => css`
    ${StyledCell}:nth-child(n+3) {
      border-top: 1px solid ${theme.colors.lightGrey};
      padding: ${theme.spacing.s1} 0;
    }

    ${StyledCell}:nth-child(-n+2) {
      padding-bottom: ${theme.spacing.s3};

      ${media.lessThan('sm')`
        padding-bottom: ${theme.spacing.s2};
      `}
    }
  `}
`;

import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { media } from 'styles/media';

export const StyledProfilePhoto = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.cream};
    border-radius: 0.6rem;
    width: 30.5rem;
    height: 30.5rem;
    display: grid;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    gap: 1rem;
    margin-bottom: 2rem;

    ${media.lessThan('sm')`
      width: calc(100vw - ${theme.spacing.s1} * 2);
      height: calc(100vw - ${theme.spacing.s1} * 2);
    `}
  `}
`;

export const StyledProfilePhotoContainer = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.cream};
    border-radius: 0.6rem;
    overflow: hidden;
    width: 30.5rem;
    height: 30.5rem;
    position: relative;

    &:hover ${StyledRemovePhotoContainer} {
      display: flex;
    }

    ${media.lessThan('sm')`
      width: calc(100vw - ${theme.spacing.s1} * 2);
      height: calc(100vw - ${theme.spacing.s1} * 2);
    `}
  `}
`;

export const StyledRemovePhotoContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 1rem;
    top: 1rem;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.cream};
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    cursor: pointer;
    display: none;
    justify-content: center;
    align-items: center;
  `}
`;

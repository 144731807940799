import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  DashboardPage,
  DownloadsPage,
  OrderPage,
  OrdersPage,
  PayoutPage,
  PayoutsPage,
  ProductPage,
  ProductsPage,
  ProfilePage,
  RefundPage,
  StatsPage,
} from './pages';
import { AcceptPage } from './pages/accept';

export const AppRoutes: FC = () => (
  <Switch>
    <Route exact path="/">
      <DashboardPage />
    </Route>
    <Route path="/dashboard">
      <DashboardPage />
    </Route>
    <Route path="/profile">
      <ProfilePage />
    </Route>
    <Route path="/designs">designs</Route>
    <Route path="/orders/:id/refund">
      <RefundPage />
    </Route>
    <Route path="/orders/:id">
      <OrderPage />
    </Route>
    <Route path="/orders">
      <OrdersPage />
    </Route>
    <Route path="/payouts/:id">
      <PayoutPage />
    </Route>
    <Route path="/payouts">
      <PayoutsPage />
    </Route>
    <Route path="/products/:id">
      <ProductPage />
    </Route>
    <Route path="/products">
      <ProductsPage />
    </Route>
    <Route path="/stats">
      <StatsPage />
    </Route>
    <Route path="/downloads">
      <DownloadsPage />
    </Route>
    <Route path="/accept">
      <AcceptPage />
    </Route>
    <Redirect to="/stats" />
    <Route path="*">404</Route>
  </Switch>
);

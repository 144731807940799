import styled, { css } from 'styled-components';
import { media } from 'styles/media';
import { animated } from 'react-spring';
import { Typography } from 'core/typography';

export const StyledSideMenuContainer = styled(animated.div)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: ${theme.layout.header.height};
    right: 0;
    height: calc(100vh - ${theme.layout.header.height});
    background-color: ${theme.colors.white};
    z-index: 1;
    padding-bottom: ${theme.spacing.s6};

    ${media.lessThan('sm')`
      top: ${theme.layout.header.heightSmaller};
      height: calc(100vh);
    `}
  `}
`;

export const StyledSideMenu = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.s5} ${theme.spacing.s5} 0;
    display: grid;
    row-gap: 2rem;

    &:not(:first-of-type) {
      padding-top: ${theme.spacing.s4};
    }
  `}
`;

export const StyledSideMenuLink = styled.div`
  ${({ theme }) => css`
    width: max-content;
    cursor: pointer;
    position: relative;

    &.active > p {
      color: ${theme.colors.black};
    }
  `}
`;

export const StyledSubmenuTitle = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
`;

export const StyleBackArrowIconContainer = styled.div`
  position: absolute;
  left: -40px;
  display: inline-flex;
`;

export const StyledSideMenuBackdrop = styled.div`
  ${({ theme }) => css`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${theme.colors.translucentBlack};
  `}
`;

export const StyledNewTag = styled(Typography)`
  position: absolute;
  top: 6px;
  right: -3rem;
`;

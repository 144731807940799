import styled, { css, DefaultTheme } from 'styled-components';
import { ButtonProps, ButtonVariant } from 'core/button/button.props';
import { getThemeColor, ThemeColor } from 'styles/theme';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';
import { media } from 'styles/media';

const getVariantBorderColor = (
  theme: DefaultTheme,
  variant: ButtonVariant,
  color: ThemeColor,
) => {
  const variantsColor = {
    default: getThemeColor(color ?? theme.colors.white),
    primary: getThemeColor(color ?? theme.colors.primary),
    secondary: getThemeColor(color ?? theme.colors.primary),
  };

  return variantsColor[variant];
};

const getVariantBackgroundColor = (
  theme: DefaultTheme,
  variant: ButtonVariant,
  color: ThemeColor,
) => {
  const variantsColor = {
    default: getThemeColor(color ?? theme.colors.white),
    primary: getThemeColor(color ?? theme.colors.primary),
    secondary: 'transparent',
  };

  return variantsColor[variant];
};

const buttonWidths = {
  md: '15rem',
  sm: '14rem',
  xl: '17rem',
};

export const StyledButton = styled.button<Partial<ButtonProps>>`
  ${({
    alignSelf,
    borderRadius,
    color,
    disabled,
    height,
    hidden,
    justifySelf,
    minWidth,
    theme,
    variant,
    width,
  }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'width',
        resolutionAwareProp: width,
        valueFormatter: value => buttonWidths[value] ?? value,
      },
      {
        cssProperty: 'min-width',
        resolutionAwareProp:
          minWidth ||
          (variant !== 'action' && {
            lg: '17rem',
            md: '15rem',
            sm: '14rem',
          }),
      },
      {
        cssProperty: 'height',
        resolutionAwareProp: variant !== 'action' ? height : 'unset',
      },
      {
        cssProperty: 'border-radius',
        resolutionAwareProp: variant === 'action' ? 'none' : borderRadius,
      },
      {
        cssProperty: 'align-self',
        resolutionAwareProp: alignSelf,
      },
      {
        cssProperty: 'justify-self',
        resolutionAwareProp: justifySelf,
      },
      {
        cssProperty: 'display',
        resolutionAwareProp: hidden,
        valueFormatter: value => (value ? 'none' : 'flex'),
      },
    ];

    return css`
      &,
      &:active,
      &:focus {
        ${generateStylesForResolutionAwareProps(entries)};
        position: relative;
        border-style: solid;
        border-width: ${variant === 'default' ? 'unset' : '1px'};
        border-color: ${getVariantBorderColor(theme, variant, color)};
        background-color: ${getVariantBackgroundColor(theme, variant, color)};
        outline: none;
        padding: 0 1.2rem;
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        pointer-events: ${disabled ? 'none' : 'all'};
        opacity: ${disabled ? 0.3 : 1};
        transition: ${theme.transitions.base};
        user-select: none;
        overflow: hidden;

        ${variant === 'action' &&
        css`
          background-color: transparent;
          border: none;
          padding: 0;
          width: max-content;
          min-width: 0;
        `}
      }
    `;
  }}
`;

export const StyledButtonContent = styled.div<ButtonProps>`
  ${({ textVariant, theme }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transform: translateY(0);
    opacity: 1;
    transition: ${theme.transitions.base};

    & > *:not(:last-child) {
      margin-right: ${textVariant === 'p3' ? '.6rem' : '.8rem'};
      position: relative;

      ${media.lessThan('sm')`
        margin-right: ${textVariant === 'p3' ? '.3rem' : '.5rem'};
      `}
    }
  `}
`;

export const StyledProcessingOverlay = styled.div<ButtonProps>`
  ${({ color, theme, variant }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${getVariantBackgroundColor(theme, variant, color)};
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  `}
`;

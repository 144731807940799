import styled, { css } from 'styled-components/macro';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';
import { media } from 'styles/media';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { TypographyProps } from './typography.props';

const h5Styles = css`
  letter-spacing: 0.3rem;
  text-transform: uppercase;

  ${media.lessThan('sm')`
    letter-spacing: .25rem;
  `}
`;

const linkStyles = css<TypographyProps>`
  color: ${({ color, theme }) =>
    theme.colors[color || ''] || theme.colors.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const labelStyles = css<TypographyProps>`
  font-weight: bold;
  position: relative;
  bottom: 10px;
`;

export const StyledText = styled.p<TypographyProps>`
  ${({
    theme,
    variant,
    transform,
    color,
    align,
    lineHeight,
    textStyle,
    fontSize,
    whiteSpace,
    padding,
    verticalPadding,
    horizontalPadding,
    paddingLeft = horizontalPadding,
    paddingRight = horizontalPadding,
    paddingTop = verticalPadding,
    paddingBottom = verticalPadding,
    margin,
    verticalMargin,
    horizontalMargin,
    marginLeft = horizontalMargin,
    marginRight = horizontalMargin,
    marginTop = verticalMargin,
    marginBottom = verticalMargin,
    alignSelf,
    justifySelf,
    display,
    minWidth,
    maxWidth,
    onClick,
  }) => {
    const htmlType = variant || 'p2';
    const themeColor = color || '';

    const defaultFontSize = fontSize || {
      lg: theme.fonts[htmlType].size,
      sm: theme.fonts[htmlType].sizeSmaller,
    };

    const defaultLineHeight = {
      lg: lineHeight ?? theme.fonts[htmlType].lineHeight,
      sm: lineHeight ?? theme.fonts[htmlType].lineHeightSmaller,
    };

    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'text-align',
        resolutionAwareProp: align,
      },
      {
        cssProperty: 'font-size',
        resolutionAwareProp: defaultFontSize,
      },
      {
        cssProperty: 'line-height',
        resolutionAwareProp: defaultLineHeight,
      },
      {
        cssProperty: 'min-width',
        resolutionAwareProp: minWidth,
      },
      {
        cssProperty: 'max-width',
        resolutionAwareProp: maxWidth,
      },
      {
        cssProperty: 'padding',
        lookIn: theme.spacing,
        resolutionAwareProp: padding,
      },
      {
        cssProperty: 'padding-left',
        lookIn: theme.spacing,
        resolutionAwareProp: paddingLeft,
      },
      {
        cssProperty: 'padding-right',
        lookIn: theme.spacing,
        resolutionAwareProp: paddingRight,
      },
      {
        cssProperty: 'padding-top',
        lookIn: theme.spacing,
        resolutionAwareProp: paddingTop,
      },
      {
        cssProperty: 'padding-bottom',
        lookIn: theme.spacing,
        resolutionAwareProp: paddingBottom,
      },
      {
        cssProperty: 'margin',
        lookIn: theme.spacing,
        resolutionAwareProp: margin,
      },
      {
        cssProperty: 'margin-left',
        lookIn: theme.spacing,
        resolutionAwareProp: marginLeft,
      },
      {
        cssProperty: 'margin-right',
        lookIn: theme.spacing,
        resolutionAwareProp: marginRight,
      },
      {
        cssProperty: 'margin-top',
        lookIn: theme.spacing,
        resolutionAwareProp: marginTop,
      },
      {
        cssProperty: 'margin-bottom',
        lookIn: theme.spacing,
        resolutionAwareProp: marginBottom,
      },
      {
        cssProperty: 'align-self',
        resolutionAwareProp: alignSelf,
      },
      {
        cssProperty: 'justify-self',
        resolutionAwareProp: justifySelf,
      },
      {
        cssProperty: 'display',
        resolutionAwareProp: display,
      },
    ];

    const fontFamily = textStyle
      ? theme.fonts[`${textStyle}FontFamily`]
      : theme.fonts[htmlType].family;

    return css`
      font-family: '${fontFamily}', 'Helvetica', sans-serif;
      color: ${theme.colors[themeColor] || color};
      text-transform: ${transform};
      transition: all ${theme.transitions.base};
      font-weight: 400;
      cursor: ${!!onClick ? 'pointer' : 'unset'};
      white-space: ${whiteSpace};

      ${generateStylesForResolutionAwareProps(entries)};

      ${variant === 'h5' && h5Styles}
      ${variant === 'link' && linkStyles}
      ${variant === 'label' && labelStyles}
      
      a {
        display: inline-block;
        font-size: inherit;
        line-height: inherit;
      }
    `;
  }}
`;

import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { getThemeColor, theme } from 'styles/theme';
import { media } from 'styles/media';

export const StyledProductSize = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const StyledProductColor = styled.div`
  ${({ color, theme }) => css`
    background-color: ${getThemeColor(color)};
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    border-radius: 50%;
    border: 1px solid ${theme.colors.cream};

    ${media.lessThan('md')`
      width: 2.4rem;
      height: 2.4rem;
      min-width: 2.4rem;
    `}
  `}
`;

export const StyledBulbChangeInstruction = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: ${theme.spacing.s3};

  &:not(:last-child) {
    margin-bottom: ${theme.spacing.s3};

    ${media.lessThan('md')`
      margin-bottom: ${theme.spacing.s2};
    `}

    ${media.lessThan('sm')`
      margin-bottom: ${theme.spacing.s1};
    `}
  }

  ${media.lessThan('md')`
    column-gap: ${theme.spacing.s2};
  `}

  ${media.lessThan('sm')`
    column-gap: ${theme.spacing.s1};
  `}
`;

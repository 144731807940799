import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgUser = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      fill={color}
      fillRule="evenodd"
      height="1em"
      opacity={0.7}
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16.5 6.5a4.5 4.5 0 11-9 0 4.5 4.5 0 119 0M12 12L3.667 22h16.667z" />
    </svg>
  );
});
export default SvgUser;

import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgSortUp = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      aria-hidden="true"
      data-icon="sort-up"
      data-prefix="fad"
      height="1em"
      viewBox="0 0 320 512"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="sort-up_svg__fa-secondary"
        d="M41.05 288.05h238c21.4 0 32.1 25.9 17 41l-119 119a23.9 23.9 0 01-33.8.1l-.1-.1-119.1-119c-15.05-15.05-4.4-41 17-41z"
        fill={color}
        opacity={0.4}
      />
      <path
        className="sort-up_svg__fa-primary"
        d="M24.05 183.05l119.1-119A23.9 23.9 0 01177 64a.94.94 0 01.1.1l119 119c15.1 15.1 4.4 41-17 41h-238c-21.45-.05-32.1-25.95-17.05-41.05z"
        fill={color}
      />
    </svg>
  );
});
export default SvgSortUp;

import styled from 'styled-components';
import { getThemeColor } from 'styles/theme';
import { css } from 'styled-components/macro';
import { media } from 'styles/media';

export const StyledChartTooltipColorContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledChartTooltip = styled.div<{ position: number }>`
  ${({ position, theme }) => css`
    box-shadow: ${theme.shadow};
    border-radius: ${theme.borderRadius};
    background-color: ${theme.colors.white};
    padding: 2rem;

    ${media.lessThan('md')`
      grid-template-columns: 1fr;
      position: fixed;
      min-width: max-content;
      left: ${position > 6 ? '-14rem' : 'unset'};
      top: -15rem;
    `}
  `}
`;

export const StyledChartTooltipContainer = styled.div<{
  multipleColumns: boolean;
}>`
  ${({ multipleColumns, theme }) => css`
    display: grid;
    grid-template-columns: ${multipleColumns
      ? 'repeat(2, max-content)'
      : '1fr'};
    column-gap: 1rem;
    row-gap: ${multipleColumns ? '.5rem' : '1rem'};

    ${media.lessThan('md')`
      grid-template-columns: 1fr;
    `}
  `}
`;

export const StyledChartTooltipColor = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  border: 1px solid ${getThemeColor('cream')};
  margin-right: 10px;
`;

import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { media } from 'styles/media';

export const StyledGiftCard = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.lightGrey};
    padding: ${theme.spacing.s3};
    border-radius: 0.6rem;
  `}
`;

export const StyledGiftCardContent = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    column-gap: ${theme.spacing.s2};

    ${media.lessThan('sm')`
      grid-template-columns: max-content 1fr;
    `}
  `}
`;

import styled from 'styled-components/macro';
import { media } from 'styles/media';
import { css } from 'styled-components';

export const StyledPeriodContainer = styled.div`
  display: grid;

  ${media.lessThan('md')`
    justify-content: center;
    justify-items: center;
  `}
`;

export const StyledChartContainer = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.lightGrey};
    border-radius: ${theme.borderRadius};
    padding: ${theme.spacing.s3};

    ${media.lessThan('md')`
      padding: ${theme.spacing.s2};
    `}
  `}
`;

export const StyledChartLegend = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 1rem;
  }

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgCameraSquare = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 50 50"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(1 1)"
      >
        <path d="M41.6 48H6.4A6.398 6.398 0 010 41.6V6.4C0 2.864 2.864 0 6.4 0h35.2C45.136 0 48 2.864 48 6.4v35.2c0 3.536-2.864 6.4-6.4 6.4z" />
        <circle cx={23.529} cy={23.529} r={11.294} />
        <path d="M5.647 9.412h6.588" />
      </g>
    </svg>
  );
});
export default SvgCameraSquare;

import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgClip = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 17 16"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M1 16h16V0H1z" />
        <path
          d="M6.142 10.204l5.651-5.852a1.298 1.298 0 011.881 0c.52.538.52 1.41 0 1.948L6.04 14.23a2.596 2.596 0 01-3.762 0 2.824 2.824 0 010-3.895l7.98-8.262a3.57 3.57 0 015.171 0c1.427 1.48 1.427 3.876 0 5.355L9.777 13.28"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
});
export default SvgClip;

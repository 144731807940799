import { Typography } from 'core/typography';
import { ReactNode, useState } from 'react';
import {
  StyleBackArrowIconContainer,
  StyledSideMenu,
  StyledSideMenuContainer,
  StyledSideMenuLink,
  StyledSubmenuTitle,
} from 'layout/side-menu/side-menu.styles';
import { useSpring } from 'react-spring';
import { FlexContainer } from 'layout/flex-container';
import { MenuItemProps } from 'layout/menu/menu.props';
import { Icon } from 'core/icon/icon';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { NavLink } from 'react-router-dom';

export const SideMenuItem = props => {
  const { item, lineHeight } = props;

  const [opened, setOpened] = useState(false);

  const toggleOpened = (): void => {
    setOpened(!opened);
  };

  const spring = useSpring({
    config: { duration: 100 },
    width: opened ? 300 : 0,
  });

  const renderMenuItem = (subItem: MenuItemProps): ReactNode => (
    <NavLink
      key={subItem.tx ?? subItem.text}
      activeClassName="active-link"
      to={subItem.to ?? '/'}
    >
      <StyledSideMenuLink>
        <TypographyWithIcon
          color={subItem.color ?? 'grey'}
          fontSize="1.4rem"
          icon={subItem.icon}
          lineHeight="4rem"
          text={subItem.text}
          tx={subItem.tx}
          variant="p2"
        />
      </StyledSideMenuLink>
    </NavLink>
  );

  return (
    <div>
      <StyledSubmenuTitle onClick={toggleOpened}>
        <Typography
          color={item.color ?? 'grey'}
          fontSize="1.4rem"
          lineHeight="4rem"
          text={item.text}
          tx={item.tx}
        />
      </StyledSubmenuTitle>

      <StyledSideMenuContainer style={spring}>
        <StyledSideMenu>
          <FlexContainer>
            <StyledSubmenuTitle onClick={toggleOpened}>
              <StyleBackArrowIconContainer>
                <Icon color="grey" name="arrow-left" width="2rem" />
              </StyleBackArrowIconContainer>

              <Typography
                color={item.color ?? 'grey'}
                lineHeight={lineHeight ?? '4rem'}
                text={item.text}
                tx={item.tx}
              />
            </StyledSubmenuTitle>
          </FlexContainer>
        </StyledSideMenu>

        <StyledSideMenu>
          {item.submenu.map((subItem: MenuItemProps) =>
            renderMenuItem(subItem),
          )}
        </StyledSideMenu>
      </StyledSideMenuContainer>
    </div>
  );
};

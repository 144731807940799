import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgDocs = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.238 0v6a.5.5 0 00.5.5h5.773V16H2.49V0h4.75zm1.55 0l4.723 4.8v.7H8.238V0h.55z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgDocs;

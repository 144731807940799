import { FC, useMemo, useState } from 'react';
import { Typography } from 'core/typography';
import { StackedContainer } from 'layout/stacked-container';
import { useTheme } from 'styled-components';
import { FlexContainer } from 'layout/flex-container';
import { Icon } from 'core/icon';
import { ResponsiveLine } from '@nivo/line';
import { BoxContainer } from 'layout/box-container';
import { getThemeColor } from 'styles/theme';
import { Link } from 'react-router-dom';
import { DatePickerRange } from 'core/date-picker-range';
import { firstDateOfMonth, formatDate } from 'utils/date';
import { DashboardStatsData, StatsFilterName } from 'services/api';
import { formatCurrency } from 'utils/formaters';
import { StyledHeading, StyledHeadingElement } from './dashboard-stats.styles';
import { DashboardStatsProps } from './dashboard-stats.types';

export const DashboardStats: FC<DashboardStatsProps> = props => {
  const { data, onDateChange } = props;
  const theme = useTheme();
  const [selectedFilter, setSelectedFilter] =
    useState<StatsFilterName>('orders');

  const chartData = useMemo(
    () => [
      {
        color: getThemeColor('primary'),
        data: (data || [])
          .filter(item => item.name === selectedFilter)
          .map(item => ({
            x: item.date,
            y: selectedFilter === 'earning' ? +item.value / 100 : +item.value,
          })),
        id: selectedFilter,
      },
    ],
    [selectedFilter, data],
  );

  const totals = useMemo(
    () =>
      (data || []).reduce(
        (acc: any, current: DashboardStatsData) => {
          if (current.name === 'orders') {
            return { ...acc, orders: acc.orders + current.value };
          }

          if (current.name === 'earning') {
            return {
              ...acc,
              earning: acc.earning + parseFloat(String(current.value)),
            };
          }

          if (current.name === 'units') {
            return { ...acc, units: acc.units + current.value };
          }

          return acc;
        },
        { earning: 0, orders: 0, units: 0 },
      ),
    [data],
  );

  const handleDateChange = values => {
    if (values.from && values.to) {
      onDateChange({
        endDate: formatDate(values.to),
        startDate: formatDate(values.from),
      });
    }
  };

  return (
    <StackedContainer
      border={`1px solid ${theme.colors.lightGrey}`}
      marginTop="s3"
      padding={{ lg: 's3', md: 's2', sm: 's1' }}
    >
      <FlexContainer
        alignItems="center"
        justifyContent="space-between"
        marginBottom={{ lg: 's5', md: 's4', sm: 's3' }}
      >
        <Typography text="Stats" variant="h3" />
        <Link to="/stats">
          <Icon color="primary" height="2rem" name="arrow-right" />
        </Link>
      </FlexContainer>

      <BoxContainer
        marginBottom={{ lg: 's5', md: 's4', sm: 's3' }}
        maxWidth={{ lg: '25rem', md: '35rem' }}
        padding="unset"
        width={{ lg: '50%', sm: '100%' }}
      >
        <DatePickerRange
          allowFutureDates={false}
          from={firstDateOfMonth()}
          to={new Date()}
          onChange={handleDateChange}
        />
      </BoxContainer>

      {!chartData?.[0]?.data?.length && (
        <BoxContainer>
          <Typography
            align="center"
            color="grey"
            padding="s5"
            text="No data to show in this period."
          />
        </BoxContainer>
      )}

      {!!chartData?.[0]?.data?.length && (
        <>
          <StyledHeading>
            <StyledHeadingElement onClick={() => setSelectedFilter('orders')}>
              <Typography
                color={selectedFilter === 'orders' ? 'primary' : 'grey'}
                text="Orders"
              />
              <Typography
                color={selectedFilter === 'orders' ? 'primary' : 'black'}
                text={totals.orders}
                textStyle="regular"
                variant="h2"
              />
            </StyledHeadingElement>

            <StyledHeadingElement onClick={() => setSelectedFilter('units')}>
              <Typography
                color={selectedFilter === 'units' ? 'primary' : 'grey'}
                text="Units"
              />
              <Typography
                color={selectedFilter === 'units' ? 'primary' : 'black'}
                text={totals.units}
                textStyle="regular"
                variant="h2"
              />
            </StyledHeadingElement>

            <StyledHeadingElement onClick={() => setSelectedFilter('earning')}>
              <Typography
                color={selectedFilter === 'earning' ? 'primary' : 'grey'}
                text="Earnings"
              />
              <Typography
                color={selectedFilter === 'earning' ? 'primary' : 'black'}
                text={formatCurrency(totals.earning)}
                textStyle="regular"
                variant="h2"
              />
            </StyledHeadingElement>
          </StyledHeading>

          <BoxContainer
            height={{ lg: '40rem', md: '30rem', sm: '20rem' }}
            maxWidth={{
              lg: '47vw',
              md: 'calc(100vw - 10rem)',
              sm: 'calc(100vw - 5rem)',
            }}
          >
            {/* @ts-ignore Component is not yet fully typed. April 08, 2022 */}
            <ResponsiveLine
              axisBottom={null}
              axisLeft={
                {
                  orient: 'left',
                  tickPadding: 5,
                  tickRotation: 0,
                  tickSize: 0,
                } as any
              }
              axisRight={null}
              axisTop={null}
              colors={[getThemeColor('primary')]}
              data={chartData}
              enableGridX={false}
              isInteractive
              margin={{ bottom: 0, left: 20, right: 0, top: 0 }}
              pointBorderColor={{ from: 'serieColor' }}
              pointBorderWidth={2}
              pointColor={{ theme: 'background' }}
              pointLabelYOffset={-12}
              pointSize={10}
              useMesh
              xScale={{ type: 'point' }}
              yFormat=" >-.2f"
              yScale={{
                max: 'auto',
                min: 'auto',
                reverse: false,
                stacked: true,
                type: 'linear',
              }}
            />
          </BoxContainer>
        </>
      )}
    </StackedContainer>
  );
};

import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgClose = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 14 12"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke={color} strokeLinecap="square">
        <path d="M12.76.24L1.24 11.76M12.76 11.76L1.24.24" />
      </g>
    </svg>
  );
});
export default SvgClose;

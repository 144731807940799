import styled from 'styled-components/macro';
import { css } from 'styled-components';

export const StyledDashboardListItem = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.lightGrey};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: ${theme.spacing.s1};
  `}
`;

import styled from 'styled-components';
import { media } from 'styles/media';

export const StyledDividedContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  ${media.lessThan('md')`
    flex-direction: column-reverse;
    justify-content: flex-end;
    background-color: ${props => props.theme.colors.cream};
  `}
`;

import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgDollar = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.552 11.66v1.234H7.365v-1.138c-.804-.036-1.584-.264-2.04-.516l.36-1.403c.503.275 1.21.527 1.99.527.695 0 1.163-.275 1.163-.743 0-.456-.384-.744-1.283-1.044-1.284-.432-2.16-1.03-2.16-2.194 0-1.068.743-1.896 2.027-2.135v-1.14H8.6v1.056a4.32 4.32 0 011.751.395l-.36 1.356c-.3-.145-.863-.42-1.727-.42-.78 0-1.03.348-1.03.684 0 .383.42.647 1.45 1.02 1.427.504 1.99 1.163 1.99 2.255 0 1.067-.744 1.978-2.123 2.207M8 0a8 8 0 100 16A8 8 0 108 0"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgDollar;

import { FC } from 'react';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { Icon } from 'core/icon';
import { Typography } from 'core/typography';
import { formatDate } from 'utils/date';
import { Stock } from 'services/api/order';
import { Thumbnail } from 'core/thumbnail';
import { formatCurrency } from 'utils/formaters';
import { StackedContainer } from 'layout/stacked-container';
import { Tooltip } from 'core/tooltip';
import { Progress } from 'core/progress';
import { ThemeColor } from 'styles/theme';
import { BoxContainer } from 'layout/box-container';
import { ResolutionAwareProp } from 'types/resolution-aware-prop.type';
import { getFileUrl } from 'gantri-components';
import { placeholderImageSrc } from 'constants/common';
import {
  StyledProgressInfo,
  StyledRefundReason,
  StyledShipment,
  StyledShipmentStock,
} from './shipping-card.styles';
import { ShippingCardProps } from './shipping-card.types';

export const ShippingCard: FC<ShippingCardProps> = ({ shipment }) => {
  const renderPriceInfo = (
    stock: Stock,
    hidden: ResolutionAwareProp<boolean>,
  ) => {
    const progressVisible =
      !!stock.beingMade &&
      !['In stock', 'Completed', 'Cancelled', 'Refunded', 'Returned'].includes(
        stock.status,
      );

    let statusColor: ThemeColor = 'grey';
    if (['Refunded', 'Cancelled'].includes(stock.status)) {
      statusColor = 'red';
    }
    if (['Return in progress'].includes(stock.status)) {
      statusColor = 'primary';
    }

    return (
      <BoxContainer hidden={hidden}>
        <Typography
          align={{ lg: 'right', sm: 'left' }}
          color="grey"
          text={formatCurrency(stock.amount.subtotal || 0)}
        />

        {progressVisible && (
          <StyledProgressInfo>
            <Typography color={statusColor} text={stock.status} />
            <Progress value={stock.completedJobPercent} width="10rem" />
            <Typography
              text={`${stock.completedJobPercent || 0}%`}
              variant="p3"
            />
          </StyledProgressInfo>
        )}

        {['Refunded', 'Cancelled'].includes(stock.status) && (
          <Typography color="red" text={stock.status} />
        )}
      </BoxContainer>
    );
  };

  return (
    <StyledShipment>
      {shipment.estimateDate.leadTime === 'immediately' && (
        <TypographyWithIcon
          color="primary"
          gap=".5rem"
          icon={<Icon color="primary" name="ray" size="2rem" />}
          text="Quick Ship"
          textStyle="bold"
        />
      )}

      {shipment.status.toLowerCase() !== 'delivered' &&
        shipment.estimateDate.shippedOnSec && (
          <Typography
            text={`Delivering on ${formatDate(
              shipment.estimateDate.deliveryBySec ||
                shipment.estimateDate.estDeliveryBySec,
              'MMM DD',
            )}`}
            variant="h4"
          />
        )}

      {shipment.status.toLowerCase() !== 'delivered' &&
        !shipment.estimateDate.shippedOnSec && (
          <TypographyWithIcon
            icon={
              <Tooltip
                content={
                  <Typography
                    color="grey"
                    text="We’re making this for you from scratch. Track live production progress of each item below. You’ll receive an email when they're shipped."
                    variant="p3"
                  />
                }
              >
                <Icon name="help" size="1.8rem" />
              </Tooltip>
            }
            iconPosition="right"
            text={`Est. delivery by ${formatDate(
              shipment.estimateDate.deliveryBySec ||
                shipment.estimateDate.estDeliveryBySec,
              'MMM DD',
            )}`}
            variant="h4"
          />
        )}

      {shipment.status.toLowerCase() === 'delivered' && (
        <Typography
          text={`Delivered on ${formatDate(
            shipment.estimateDate.deliveredOnSec,
            'MMM DD',
          )}`}
          variant="h4"
        />
      )}

      <Typography
        color={shipment.status === 'delivered' ? 'black' : 'grey'}
        text={shipment.status}
      />

      {!!shipment.trackingNumber && (
        <TypographyWithIcon
          color="grey"
          icon={
            <a
              href={`https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=${shipment.trackingNumber}`}
              rel="noreferrer"
              target="_blank"
            >
              #{shipment.trackingNumber}
            </a>
          }
          iconPosition="right"
          text="Tracking"
        />
      )}

      <StackedContainer gap="s2" marginTop="s3" padding="unset">
        {shipment.stocks.map((stock: Stock) => (
          <StyledShipmentStock key={stock.id}>
            <Thumbnail
              size={{ lg: '8rem', sm: '6rem' }}
              src={
                stock.product.imageUrl
                  ? getFileUrl<'products'>({
                      directory: 'products',
                      fileName: stock.product.imageUrl,
                      fileType: 'product-photos',
                      identifiers: {
                        productId: stock.product.id,
                        sku: stock.product.sku,
                      },
                    })
                  : placeholderImageSrc
              }
            />

            <div>
              <Typography text={stock.product.fullName} />
              <Typography color="grey" text={stock.product.designer} />
              <Typography
                color="grey"
                text={`${stock.product.color.name}, ${stock.product.height}" tall`}
              />
              {renderPriceInfo(stock, { lg: true, sm: false })}

              {!!stock.refundReason && (
                <StyledRefundReason>
                  <Typography text="Refund reason" />
                  <Typography color="grey" text={stock.refundReason} />
                </StyledRefundReason>
              )}
            </div>

            {renderPriceInfo(stock, { lg: false, sm: true })}
          </StyledShipmentStock>
        ))}
      </StackedContainer>
    </StyledShipment>
  );
};

import { FC, useEffect, useMemo } from 'react';
import {
  StyledBody,
  StyledContent,
  StyledContentContainer,
  StyledTopContainer,
  StyledTopNotificationsContainer,
} from 'layout/layout/base-layout.styles';
import { SideMenu } from 'layout/side-menu';
import { useLayout } from 'context/layout.context';
import { NotificationsManager } from 'layout/notifications-manager';
import { Menu } from 'layout/menu';
import { LeftMenu } from 'layout/right-menu';
import { useQuery } from 'react-query';
import { api, GET_PAYOUTS, GET_USER } from 'services/api';
import { UserData } from 'services/api/auth';
import { AUTH_TOKEN } from 'constants/common';
import { useCookies } from 'react-cookie';
import { environment } from 'config/environment';
import { useModal } from 'context/modal.context';
import { InactiveModal } from 'layout/modals';
import { initializeFirebase } from 'utils/firebase';
import { LoadingOverlay } from 'layout/loading-overlay';
import { useAuth } from 'context/auth.context';
import { getAnalyticsUserId, loadAnalytics } from 'utils/analytics';
import { useAsync } from 'react-use';
import { useLocation } from 'react-router-dom';

const redirectToShopSignInPage = () => {
  window.location.replace(
    `${environment.SHOP_URL}/sign-up?redirectUrl=${window.location.href}`,
  );
};

const redirectToOldGraphite = () => {
  window.location.replace(`${environment.OLD_GRAPHITE_URL}`);
};

export const BaseLayout: FC = ({ children }) => {
  const { hideSideMenu, sideMenuVisible, toggleSideMenuVisibility } =
    useLayout();

  const [cookies, , removeCookie] = useCookies([AUTH_TOKEN]);
  const [, authDispatch] = useAuth();
  const location = useLocation();

  const query = useQuery<UserData, { message: string }>(
    GET_USER,
    api.auth.getLogged,
    {
      refetchOnWindowFocus: false,
    },
  );

  const payoutsQuery = useQuery(GET_PAYOUTS, () => api.designer.getPayouts(), {
    enabled: query.status === 'success',
  });

  useAsync(async () => {
    const loaded = await loadAnalytics();

    if (loaded) {
      authDispatch({
        id: getAnalyticsUserId(),
        type: 'UPDATE_BROWSER_ID',
      });
    }
  }, []);

  const { showModal: showInactiveModal } = useModal({
    template: <InactiveModal />,
    variant: 'dialog',
    width: '46rem',
  });

  const isGuestPage = useMemo(
    () => location.pathname === '/accept',
    [location.pathname],
  );

  useEffect(() => {
    if (
      query.status === 'success' &&
      !query.data.data.isDesigner &&
      !isGuestPage
    ) {
      redirectToShopSignInPage();
    }
  }, [query]);

  useEffect(() => {
    if (location.pathname === '/' && query.status !== 'loading') {
      if (query.error?.message === 'No authorization token was found') {
        redirectToOldGraphite();
      }

      if (query.status === 'success' && !query.data.data.isDesigner) {
        redirectToOldGraphite();
      }
    }
  }, [location, query, cookies]);

  useEffect(() => {
    if (
      query.status === 'success' &&
      !isGuestPage &&
      !query?.data?.data?.designerInfo?.active
    ) {
      if (query.data?.data?.isDesigner) {
        showInactiveModal();
      }
    }
  }, [
    location,
    query.status,
    query?.data?.data?.designerInfo?.active,
    showInactiveModal,
  ]);

  useEffect(() => {
    if (query.data?.firebaseToken) {
      initializeFirebase(query.data?.firebaseToken);
    }
  }, [query.data?.firebaseToken]);

  const logout = async () => {
    await api.auth.logout();

    removeCookie(AUTH_TOKEN, {
      domain: environment.COOKIES_DOMAIN,
      path: '/',
    });
  };

  if (!query?.data?.data?.designerInfo && !isGuestPage) {
    return <LoadingOverlay />;
  }

  return (
    <StyledBody>
      <StyledTopNotificationsContainer>
        <NotificationsManager />
      </StyledTopNotificationsContainer>

      {location.pathname === '/accept' && <div id="content">{children}</div>}

      {location.pathname !== '/accept' && (
        <>
          <StyledTopContainer>
            <Menu
              userData={query.data?.data}
              onLogout={logout}
              onMenuButtonClick={toggleSideMenuVisibility}
            />
          </StyledTopContainer>

          <StyledContentContainer>
            <StyledContent id="content">
              <LeftMenu
                hasPayouts={!!payoutsQuery.data?.data?.length}
                hasProducts={!!query?.data?.data?.designerInfo?.productsCount}
              />
              {children}
            </StyledContent>
          </StyledContentContainer>
          <SideMenu
            hasPayouts={!!payoutsQuery.data?.data?.length}
            user={query.data?.data}
            visible={sideMenuVisible}
            onLogout={logout}
            onMenuClose={hideSideMenu}
          />
        </>
      )}
    </StyledBody>
  );
};

import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { media } from 'styles/media';
import { getThemeColor, ThemeColor } from 'styles/theme';
import { Property } from 'csstype';

export const StyledFitVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

export const QuotesIcon = styled.div<{ color?: string }>`
  ${({ color, theme }) => css`
    height: 3rem;
    position: relative;
    overflow: hidden;
    text-align: center;
    min-width: 4rem;

    &:before {
      position: absolute;
      content: '\\201C';
      font-family: Georgia, sans-serif;
      font-size: 10rem;
      opacity: 0.3;
      top: 0;
      left: 0;
      right: 0;
      color: ${color || theme.colors.grey};
      line-height: 7.8rem;
    }
  `}
`;

export const StyledTextBlock = styled.p<{ size?: string }>`
  ${({ size, theme }) => css`
    color: ${theme.colors.grey};
    white-space: pre-wrap;
    font-size: ${size || '1.5rem'};

    a {
      display: inline-block;
      font-size: ${size || '1.5rem'};
    }
  `}
`;

export const StyledFullPageContent = styled.div`
  ${({ theme }) => css`
    min-height: calc(
      100vh - ${theme.layout.header.height} - ${theme.layout.topMenuHeight}
    );

    ${media.lessThan('sm')`
      min-height: calc(
        100vh - ${theme.layout.header.heightSmaller} - ${theme.layout.topMenuHeight}
      );
    `}
  `}
`;

export const StyledContentPanel = styled.div<{
  color?: ThemeColor;
  cursor?: Property.Cursor;
}>`
  ${({ color, cursor, theme }) => css`
    background-color: ${color ? getThemeColor(color) : theme.colors.white};
    border-radius: ${theme.borderRadius};
    padding: ${theme.spacing.s3};
    cursor: ${cursor || 'unset'};

    ${media.lessThan('md')`
      padding: ${theme.spacing.s2};
    `}
  `}
`;

export const StyledInlineContainer = styled.div`
  & > * {
    display: inline;
  }
`;

export const StyledInlineBlockContainer = styled.div`
  & > * {
    display: inline-block;
  }
`;

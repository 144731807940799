import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

export const StyledCollapsibleContainerHeading = styled.div<{
  collapsed: boolean;
}>`
  ${({ collapsed }) => css`
    margin-bottom: ${collapsed ? 'unset' : '1rem'};
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
  `}
`;

export const StyledCollapsibleContainerContent = styled(animated.div)`
  overflow-y: hidden;
`;

export const StyledCollapsibleIcon = styled.div<{ collapsed: boolean }>`
  ${({ collapsed, theme }) => css`
    position: relative;

    &:before,
    &:after {
      content: '';
      width: 9px;
      height: 2px;
      display: inline-flex;
      background-color: ${theme.colors.grey};
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &:after {
      transition: all 0.1s linear;
      transform: ${collapsed ? 'rotate(90deg)' : 'unset'};
    }
  `}
`;

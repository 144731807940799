import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgTrash = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 18 18"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke={color} strokeWidth={1.004}>
        <path d="M6.23 3.839h0c0-1.425 1.233-2.58 2.753-2.58h0c1.52 0 2.752 1.155 2.752 2.58h0" />
        <path
          d="M15.039 5.903l-1.101 10.839h-9.91L2.927 5.903M16.69 3.839H1.275"
          strokeLinecap="square"
        />
      </g>
    </svg>
  );
});
export default SvgTrash;

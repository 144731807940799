import { FC, useCallback, useMemo, useState } from 'react';
import { Page } from 'core/page';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api, GET_USER, UserData } from 'services/api';
import { Typography } from 'core/typography';
import { useHistory, useParams } from 'react-router-dom';
import { LoadingOverlay } from 'layout/loading-overlay';
import {
  CancelOrRefundRequest,
  GET_ORDER,
  OrderAmount,
  Stock,
} from 'services/api/order';
import { BoxContainer } from 'layout/box-container';
import { StackedContainer } from 'layout/stacked-container';
import { Grid } from 'layout/grid';
import { Checkbox } from 'core/checkbox';
import { Thumbnail } from 'core/thumbnail';
import { formatCurrency } from 'utils/formaters';
import { useTheme } from 'styled-components';
import { Cell } from 'layout/cell';
import { SelectInput } from 'core/select-input';
import { TextInput } from 'core/text-input';
import { ResolutionAwareProp } from 'types/resolution-aware-prop.type';
import { Button } from 'core/button';
import { useNotification } from 'context/notification.context';
import { getFileUrl } from 'gantri-components';
import { placeholderImageSrc } from 'constants/common';

const reasonOptions = [
  "Size doesn't fit",
  "Style doesn't fit",
  'Shipping takes too long',
  'Quality issue',
  'Other',
];

export const RefundPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [selected, setSelected] = useState({});
  const [reasons, setReasons] = useState({});

  const query = useQuery([GET_ORDER, id], () => api.order.getOrder(+id));
  const queryClient = useQueryClient();
  const userQuery = useQuery<UserData>(GET_USER, api.auth.getLogged, {
    refetchOnWindowFocus: false,
  });
  const history = useHistory();
  const { hidePreloader, showPreloader } = useNotification();
  const theme = useTheme();

  const cancelOrRefundMutation = useMutation(
    async (data: CancelOrRefundRequest) => api.order.cancelOrRefund(+id, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(GET_ORDER);
        history.push(`/orders/${id}`);
      },
    },
  );

  const getShipmentAmount = useCallback(
    (shipmentId: number) => {
      const shipment = (query.data?.order?.shipments || []).find(
        item => item.id === shipmentId,
      );

      return !!shipment && shipment.shippingOptions.type === 'fastest'
        ? 5000
        : 0;
    },
    [query.data?.order?.shipments],
  );

  const giftInfo = query.data?.order?.gift ?? null;
  const orderInfo = query.data?.order?.amount ?? null;
  const orderSubtotal = orderInfo ? orderInfo.subtotal : 0;
  const shipments = query.data?.order?.shipments ?? null;

  let refundedAmount = 0;
  let giftAmount = 0;

  if (shipments) {
    shipments.forEach(shipment => {
      shipment.stocks.forEach(stock => {
        if (stock.status === 'Refunded') {
          refundedAmount += stock.amount ? stock.amount.subtotal : 0;
        }
      });
    });
  }

  const summary = useMemo<any>(
    () =>
      Object.values(selected).reduce(
        (acc: OrderAmount, current: Stock) => {
          const currentShipment = getShipmentAmount(current.shipmentId);
          let remainingAmount = 0;
          const newSubtotal = acc.subtotal + current.amount.subtotal;
          const newTax = acc.tax + current.amount.tax;
          const newCredit = acc.credit + (current.amount.credit || 0);
          const newShipping = acc.shipping + currentShipment;

          if (giftInfo && giftInfo.type === 'Discount') {
            const allSubtotal = refundedAmount + newSubtotal;
            remainingAmount = orderSubtotal - allSubtotal;

            if (orderSubtotal - allSubtotal > giftInfo.amount) {
              giftAmount = 0;
            } else {
              giftAmount = giftInfo.amount - remainingAmount;
            }
          }

          if (giftAmount > newSubtotal) {
            giftAmount = -newSubtotal;
          } else {
            giftAmount = -giftAmount || 0;
          }

          if (giftInfo && giftInfo.type === 'Discount %') {
            const percentOff = giftInfo.amount / 100;
            giftAmount = -newSubtotal * percentOff;
          }

          const newTotal =
            newSubtotal + newTax + currentShipment - newCredit + giftAmount;

          return {
            credit: newCredit,
            gift: giftAmount,
            shipping: newShipping,
            subtotal: newSubtotal,
            tax: newTax,
            total: newTotal,
          };
        },
        {
          credit: 0,
          gift: 0,
          shipping: 0,
          subtotal: 0,
          tax: 0,
          total: 0,
        },
      ),
    [selected],
  );

  const onReasonSelected = (stockId: any, value: string) => {
    setReasons(old => ({
      ...old,
      [stockId]: {
        description: '',
        reason: value,
      },
    }));
  };

  const onSelected = (value: boolean, stock: Stock) => {
    if (value) {
      setSelected(old => ({ ...old, [stock.id]: stock }));
    } else {
      const temporal = { ...selected };
      delete temporal[stock.id];
      setSelected(temporal);
      onReasonSelected(stock.id, '');
    }
  };

  const onReasonDescriptionChange = (stockId: number, value: any) => {
    setReasons(old => ({
      ...old,
      [stockId]: {
        description: value,
        reason: reasons[stockId].reason,
      },
    }));
  };

  const confirmButtonDisabled = useMemo(() => {
    const records = Object.values(selected).filter(
      (stock: Stock) => !!stock.id,
    );

    if (!records.length) {
      return true;
    }

    return records.some((stock: Stock) => {
      const currentReason = reasons[stock.id];

      if (!currentReason || !currentReason.reason) {
        return true;
      }

      return (
        ['Quality issue', 'Other'].includes(currentReason.reason) &&
        !currentReason.description
      );
    });
  }, [selected, reasons]);

  const onConfirm = async () => {
    const refundItems = Object.values(selected).map((stock: Stock) => ({
      id: stock.stockInfoId,
      refundReason: reasons[stock.id].description
        ? `${reasons[stock.id].reason}: ${reasons[stock.id].description}`
        : reasons[stock.id].reason,
    }));

    try {
      showPreloader();

      await cancelOrRefundMutation.mutateAsync({
        refundItems,
        status: 'Refund',
      });
    } finally {
      hidePreloader();
    }
  };

  if (!query.data?.order) {
    return <LoadingOverlay />;
  }

  const renderReasons = (
    stock: Stock,
    hidden: ResolutionAwareProp<boolean> = false,
  ) => (
    <StackedContainer
      gap="s1"
      hidden={hidden}
      marginTop={{ lg: 's2', sm: 's1' }}
      maxWidth="30rem"
      padding="unset"
    >
      <SelectInput
        options={reasonOptions}
        placeholder="Select reason"
        value={reasons[stock.id]?.reason || ''}
        onChange={value => onReasonSelected(stock.id, value)}
      />

      {['Order', 'Designer', 'Third Party'].includes(stock.type) &&
        ['Quality issue', 'Other'].includes(reasons[stock.id]?.reason) && (
          <TextInput
            placeholder="Specify reason"
            value={reasons[stock.id]?.description}
            onTextChange={(value: string) =>
              onReasonDescriptionChange(stock.id, value)
            }
          />
        )}
    </StackedContainer>
  );

  return (
    <Page>
      <BoxContainer width={{ lg: '66vw', md: 'unset' }}>
        <Typography marginBottom="s1" text="Create Refund" variant="h2" />
        <Typography
          color="grey"
          text="Select items below to create a refund."
        />

        {!!query.data?.order?.stocks.length && (
          <StackedContainer
            columnGap="s2"
            padding="unset"
            rowGap={{ lg: 's5', md: 's4' }}
            verticalMargin="s5"
          >
            {query.data.order.stocks.map((stock: Stock, index: number) => (
              <Grid
                key={stock.id || index}
                columns="2.4rem 8rem 1fr max-content"
              >
                <Checkbox
                  checked={selected[stock.id]}
                  disabled={
                    !stock.id ||
                    ['Refunded', 'Return in progress', 'Returned'].includes(
                      stock.status,
                    ) ||
                    stock.userId !== userQuery?.data?.data?.id
                  }
                  onChange={(value: boolean) => onSelected(value, stock)}
                />

                <Thumbnail
                  size="8rem"
                  src={
                    stock.product.imageUrl
                      ? getFileUrl<'products'>({
                          directory: 'products',
                          fileName: stock.product.imageUrl,
                          fileType: 'product-photos',
                          identifiers: {
                            productId: stock.product.id,
                            sku: stock.product.sku,
                          },
                        })
                      : placeholderImageSrc
                  }
                />

                <div>
                  <Typography text={stock.product.fullName} />
                  <Typography color="grey" text={stock.product.designer} />
                  <Typography
                    color="grey"
                    text={`${stock.product.color.name}, ${stock.product.size.name}" tall`}
                  />
                  <Typography
                    color="grey"
                    display={{ lg: 'none', md: 'block' }}
                    text={formatCurrency(stock.amount.subtotal)}
                  />
                  <Typography
                    color="grey"
                    display={{ lg: 'none', md: 'block' }}
                    text={stock.status}
                  />

                  {!!selected[stock.id] &&
                    renderReasons(stock, { lg: false, md: true })}
                </div>

                <div>
                  <Typography
                    color="grey"
                    display={{ lg: 'block', md: 'none' }}
                    text={formatCurrency(stock.amount.subtotal)}
                  />
                  <Typography
                    color="grey"
                    display={{ lg: 'block', md: 'none' }}
                    text={stock.status}
                  />
                </div>

                {!!selected[stock.id] && (
                  <>
                    <Cell hidden={{ lg: true, md: false }} />
                    <Cell hidden={{ lg: true, md: false }} width={3}>
                      {renderReasons(stock)}
                    </Cell>
                  </>
                )}
              </Grid>
            ))}
          </StackedContainer>
        )}

        <Grid
          columns="1fr max-content"
          gap="unset"
          paddingTop="s3"
          style={{ borderTop: theme.border }}
        >
          <Cell width={2}>
            <Typography marginBottom="s2" text="Summary" variant="h4" />
          </Cell>

          <Typography color="grey" text="Subtotal" />
          <Typography
            align="right"
            color="grey"
            text={formatCurrency(summary.subtotal)}
          />

          <Typography color="grey" text="Shipping" />
          <Typography
            align="right"
            color="grey"
            text={formatCurrency(summary.shipping)}
          />

          <Typography color="grey" text="Tax" />
          <Typography
            align="right"
            color="grey"
            text={formatCurrency(summary.tax)}
          />

          {!!summary.credit && (
            <>
              <Typography color="grey" text="Credit" />
              <Typography
                align="right"
                color="grey"
                text={formatCurrency(summary.credit)}
              />
            </>
          )}

          {!!summary.gift && (
            <>
              <Typography color="grey" text="Gift discount" />
              <Typography
                align="right"
                color="grey"
                text={formatCurrency(summary.gift)}
              />
            </>
          )}

          <Typography text="Total Refunds" />
          <Typography align="right" text={formatCurrency(summary.total)} />
        </Grid>

        <Grid
          columnGap="s1"
          columns={{ lg: 'repeat(2, max-content)', md: 'repeat(2, 1fr)' }}
          marginTop="s5"
        >
          <Button
            linkHref={`/orders/${id}`}
            text="Cancel"
            variant="secondary"
            width="100%"
          />

          <Button
            disabled={confirmButtonDisabled}
            text="Confirm"
            onClick={onConfirm}
          />
        </Grid>
      </BoxContainer>
    </Page>
  );
};

import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgHelp = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 16 16"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 0a8.001 8.001 0 010 16A8 8 0 118 0zm-.324 9.997c-.623 0-1.067.444-1.067 1.09 0 .624.432 1.092 1.067 1.092.66 0 1.08-.468 1.08-1.092-.012-.647-.432-1.09-1.08-1.09zm.168-6.177c-.85 0-1.56.24-2.003.493L6.25 5.62c.312-.216.792-.36 1.188-.36.6.012.875.3.875.72 0 .395-.312.79-.696 1.258-.54.636-.743 1.26-.707 1.87l.012.312h1.595v-.216c-.012-.54.18-1.007.612-1.5.456-.504 1.03-1.103 1.03-2.014 0-.996-.732-1.872-2.315-1.872z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgHelp;

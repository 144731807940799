import styled, { css } from 'styled-components';
import SvgChevronDown from 'core/icon/generated/ChevronDown';

export const StyledSelectContainer = styled.div<{ invalid: any }>`
  ${({ invalid, theme }) => css`
    border-bottom: 1px solid
      ${invalid ? theme.colors.red : theme.colors.lightGrey};
    padding-bottom: 5px;
    position: relative;
    height: 100%;
    max-height: 10.6rem;
    display: initial;
  `}
`;

export const StyledSelect = styled.select`
  border: none;
  outline: none;
  background-color: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-family: 'Univers 55 Roman', 'Helvetica', sans-serif;
  font-size: 15px;
  position: relative;
  width: 100%;
  cursor: pointer;
`;

export const StyledChevronDown = styled(SvgChevronDown)`
  position: absolute;
  right: 0;
  bottom: 1rem;
  transform: scale(0.8);
`;

export const StyledSelectPlaceholder = styled.span<{ invalid: any }>`
  ${({ invalid, theme }) => css`
    position: absolute;
    left: 0;
    color: ${invalid ? theme.colors.red : theme.colors.grey};
    pointer-events: none;
  `}
`;

import { FC } from 'react';
import { LayoutProvider } from 'context/layout.context';
import { NotificationsProvider } from 'context/notification.context';
import { AuthProvider } from 'context/auth.context';
import { theme } from 'styles/theme';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 100000 } },
});

export const AppProviders: FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <LayoutProvider>
        <NotificationsProvider>
          <AuthProvider>{children}</AuthProvider>
        </NotificationsProvider>
      </LayoutProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ThemeProvider>
);

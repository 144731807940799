import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgHand = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 16 20"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14.674 9.372v5.441c0 2.313-1.796 4.187-4.011 4.187H7.986c-1.373 0-2.652-.734-3.389-1.944-1.38-2.269-3.096-6.01-3.096-6.01-.27-.4-.225-.944.106-1.289a.933.933 0 011.307-.052l2.131 2.597V3.93c0-.694.54-1.256 1.204-1.256.665 0 1.202.562 1.202 1.256v5.023" />
        <path d="M7.453 7.28V2.255c0-.694.539-1.255 1.204-1.255.664 0 1.203.56 1.203 1.256v5.022m0 1.674V3.93c0-.694.539-1.256 1.203-1.256.665 0 1.204.562 1.204 1.256v5.023M12.267 10.21V6.44c0-.693.539-1.255 1.203-1.255.665 0 1.204.562 1.204 1.255v5.024" />
      </g>
    </svg>
  );
});
export default SvgHand;

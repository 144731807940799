import { FC } from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { UserNotification } from 'types/user.type';
import {
  StyledNotification,
  StyledNotificationDateRow,
} from 'layout/menu/components/notifications-overlay/notifications-overlay.styles';
import { Typography } from 'core/typography';
import { formatDate, getDuration, toDate } from 'utils/date';
import { environment } from 'config/environment';
import { NotificationsOverlayPresets } from './notifications-overlay.presets';
import { NotificationsOverlayProps } from './notifications-overlay.types';

const getDateMessage = (date: Date): string => {
  const duration = getDuration(date);

  if (duration.years() || duration.months() || duration.days() > 10) {
    return formatDate(date, 'M/DD/YY');
  }

  return toDate(date);
};

export const NotificationsOverlay: FC<NotificationsOverlayProps> = props => {
  const { notifications } = {
    ...NotificationsOverlayPresets,
    ...props,
  };

  return (
    <StackedContainer
      horizontalPadding={{ lg: 's3', md: 's2' }}
      verticalMargin="s2"
      width="32.5rem"
    >
      {!notifications.length && (
        <Typography color="grey" tx="common.emptyNotification" />
      )}

      {notifications.map((notification: UserNotification) => (
        <StyledNotification key={notification.id}>
          <Typography
            color="grey"
            fontSize="1.4rem"
            text={notification.header}
          />
          <Typography color="grey" fontSize="1.4rem" text={notification.body} />

          <StyledNotificationDateRow>
            <Typography
              color="grey"
              text={getDateMessage(notification.createdAt)}
              variant="p3"
            />

            {!!notification?.link?.url && (
              <a
                href={`${environment.OLD_GRAPHITE_URL}${notification.link.url}`}
              >
                <Typography
                  color={notification.link.color || 'primary'}
                  text={notification.link.label}
                  variant="p3"
                />
              </a>
            )}
          </StyledNotificationDateRow>
        </StyledNotification>
      ))}
    </StackedContainer>
  );
};

import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgSettings = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 10.462C6.616 10.462 5.5 9.36 5.5 8S6.616 5.537 8 5.537 10.507 6.64 10.507 8 9.386 10.462 8 10.462m6.12-3.797L16 5.6l-1.88-3.2-1.812 1.066c-.666-.6-1.65-1.066-2.276-1.338V0H6.268v2.128c-1.254.273-1.76.738-2.426 1.34L1.917 2.4.015 5.6 1.89 6.665a5.925 5.925 0 00-.004 2.67L0 10.4l1.88 3.2 1.963-1.068c.667.602 1.172 1.068 2.426 1.34V16h3.764v-2.128c.627-.273 1.6-.74 2.277-1.34l1.81 1.068L16 10.4l-1.88-1.066a5.97 5.97 0 00-.001-2.67"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgSettings;

import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgBell = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.859 18.498H2l2.168-2.743a.442.442 0 00.094-.285V7.004c0-3.21 2.41-6.01 5.848-6.794a8.705 8.705 0 013.786-.003 7.963 7.963 0 013.57 1.85c1.458 1.305 2.276 3.088 2.27 4.947v8.476a.439.439 0 00.096.287L22 18.51l-6.141-.012zM10.024 20c.121 1.123.955 2 1.976 2s1.856-.877 1.976-2h1.998c-.135 2.227-1.856 4-3.974 4-2.118 0-3.84-1.773-3.974-4h1.998z"
        fill={color}
        fillRule="evenodd"
        opacity={0.7}
      />
    </svg>
  );
});
export default SvgBell;

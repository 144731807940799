import styled, { css } from 'styled-components';

export const StyledSelectContainer = styled.div<{ invalid: boolean }>`
  ${({ invalid, theme }) => css`
    border-bottom: 1px solid
      ${invalid ? theme.colors.red : theme.colors.lightGrey};
    padding-bottom: 5px;
    position: relative;
    max-height: 10.6rem;
    height: 3rem;
    display: grid;
    grid-template-columns: 1fr 1rem;
    align-items: center;
  `}
`;

export const StyledContent = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.lightGrey};
    padding-bottom: 0.8rem;
    display: flex;
    justify-content: space-between;
  `}
`;

export const StyledArrowIcon = styled.span<{ rotated?: boolean }>`
  ${({ rotated, theme }) => css`
    transition: ${theme.transitions.base};
    transform: ${rotated ? 'rotate(180deg)' : 'unset'};
    position: relative;
    top: 1px;
  `}
`;

export const StyledFiltersBackdrop = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `}
`;

export const StyledValueContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, max-content);
    column-gap: 1rem;
  `}
`;

import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgWishlist = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0L5.528 5.266 0 6.11l4 4.1L3.056 16 8 13.266 12.944 16 12 10.21l4-4.1-5.528-.845L8 0z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgWishlist;

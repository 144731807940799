import styled, { css } from 'styled-components/macro';
import { media } from 'styles/media';

export const StyledBody = styled.div`
  display: grid;
  min-height: 100vh;
  align-content: start;
`;

export const StyledTopNotificationsContainer = styled.div`
  position: relative;
  z-index: 1000011;
`;

export const StyledTopContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    z-index: 10;
    background-color: ${theme.colors.black};
    user-select: none;
  `}
`;

export const StyledContentContainer = styled.div<{
  topMenuHeight?: string;
}>`
  ${({ theme, topMenuHeight = '3.2rem' }) => css`
    position: relative;
    z-index: 0;
  `}
`;

export const StyledContent = styled.div`
  position: relative;
  min-height: calc(100vh - ${({ theme }) => theme.layout.header.height});
  max-height: calc(100vh - ${({ theme }) => theme.layout.header.height});
  display: grid;
  grid-template-columns: 15vw 1fr;
  column-gap: 3rem;
  padding: ${({ theme }) => `${theme.spacing.s5} ${theme.spacing.s3} 0 0`};
  overflow-y: auto;
  align-content: start;

  ${media.lessThan('md')`
    grid-template-columns: 1fr;
    padding: ${({ theme }) => `${theme.spacing.s5} ${theme.spacing.s3}`};
  `}

  ${media.lessThan('sm')`
    min-height: calc(100vh - ${({ theme }) =>
      theme.layout.header.heightSmaller});
    max-height: calc(100vh - ${({ theme }) =>
      theme.layout.header.heightSmaller});
    padding: ${({ theme }) => `${theme.spacing.s5} ${theme.spacing.s1}`};
  `}
`;

export const StyledObservableDiv = styled.div`
  width: 100%;
  height: 3px;
  position: absolute;
  top: 1px;
  z-index: 100000;
`;

export const StyledInlineFlexContainer = styled.div`
  display: inline-flex;
  align-items: center;

  ${media.lessThan('sm')`
    flex-direction: column;
    align-items: start;
  `}
  *:not(:first-child) {
    margin-left: 1rem;

    ${media.lessThan('sm')`
      margin-left: unset;
    `}
  }
`;

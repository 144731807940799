import styled, { css } from 'styled-components';
import { ButtonToggleGroupProps } from 'core/button-toggle-group/button-toggle-group.props';
import { getThemeColor } from 'styles/theme';

export const StyledToggleButtonGroup = styled.div<ButtonToggleGroupProps>`
  ${({ backgroundColor, containerPadding, itemsGap }) => css`
    display: flex;
    user-select: none;
    height: fit-content;
    background-color: ${getThemeColor(backgroundColor)};
    padding: ${containerPadding};
    border-radius: 2rem;

    & > *:not(:last-child) {
      margin-right: ${itemsGap};
    }
  `}
`;

import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgInstagram = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.16 0c2.223.004 2.678.022 3.788.072 1.277.058 2.15.261 2.912.558.79.307 1.459.717 2.126 1.384A5.883 5.883 0 0123.37 4.14c.297.763.5 1.635.558 2.912C23.986 8.332 24 8.741 24 12c0 3.259-.014 3.668-.072 4.948-.058 1.277-.261 2.15-.558 2.912a5.883 5.883 0 01-1.384 2.126 5.883 5.883 0 01-2.126 1.384c-.763.297-1.635.5-2.912.558-1.28.058-1.689.072-4.948.072-3.259 0-3.668-.014-4.948-.072-1.277-.058-2.15-.261-2.912-.558a5.883 5.883 0 01-2.126-1.384A5.882 5.882 0 01.63 19.86c-.297-.763-.5-1.635-.558-2.912-.05-1.11-.068-1.565-.071-3.788v-2.32c.003-2.223.02-2.678.071-3.788C.13 5.775.333 4.902.63 4.14a5.882 5.882 0 011.384-2.126A5.882 5.882 0 014.14.63C4.903.333 5.775.13 7.052.072 8.162.022 8.617.004 10.84.001zm-.02 2.163h-2.28c-2.181.003-2.612.02-3.709.07-1.17.052-1.805.248-2.228.412-.56.218-.96.478-1.38.898-.42.42-.68.82-.898 1.38-.164.423-.36 1.058-.413 2.228-.05 1.097-.066 1.528-.07 3.71v2.279c.004 2.181.02 2.612.07 3.709.053 1.17.249 1.805.413 2.228.218.56.478.96.898 1.38.42.42.82.68 1.38.898.423.164 1.058.36 2.228.413 1.096.05 1.528.066 3.71.07h2.279c2.181-.004 2.613-.02 3.709-.07 1.17-.053 1.805-.249 2.228-.413.56-.218.96-.478 1.38-.898.42-.42.68-.82.898-1.38.164-.423.36-1.058.413-2.228.05-1.097.066-1.528.07-3.71V10.86c-.004-2.181-.02-2.612-.07-3.709-.053-1.17-.249-1.805-.413-2.228a3.717 3.717 0 00-.898-1.38c-.42-.42-.82-.68-1.38-.898-.423-.164-1.058-.36-2.228-.413-1.097-.05-1.528-.066-3.71-.07zM12 5.838a6.162 6.162 0 110 12.324 6.162 6.162 0 010-12.324zM12 8a4 4 0 100 8 4 4 0 000-8zm6.406-3.846a1.44 1.44 0 110 2.88 1.44 1.44 0 010-2.88z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgInstagram;

import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgBranch = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 80 80"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 80h80V0H0z" fill="#FFF" />
        <path
          d="M71.391 44.863L44.956 70.841M71.398 57.856L58.181 70.845"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <g
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path d="M78 71.739c0 3.096-2.551 5.604-5.7 5.604H7.699c-3.148 0-5.7-2.508-5.7-5.604V8.258c0-3.092 2.552-5.6 5.7-5.6h64.6c3.15 0 5.7 2.508 5.7 5.6v63.48zM39.671 30.58L20.639 49.285" />
          <path d="M51.567 18.892s-5.024 44.945-29.594 29.08C5.827 23.83 51.567 18.892 51.567 18.892z" />
          <path d="M34.12 43.828h-7.93v-7.791M38.877 36.817h-5.55v-5.458M42.05 30.583h-2.38v-2.341" />
        </g>
      </g>
    </svg>
  );
});
export default SvgBranch;

import { FC } from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { Icon } from 'core/icon';
import { environment } from 'config/environment';
import { StyledLeftMenuItem } from 'layout/right-menu/left-menu.styles';
import { NavLink } from 'react-router-dom';

export const LeftMenu: FC<{
  hasPayouts: boolean;
  hasProducts: boolean;
}> = props => (
  <div>
    <StackedContainer
      gap="s2"
      height="auto"
      hidden={{ lg: false, md: true }}
      style={{ position: 'sticky', top: 0 }}
    >
      <NavLink activeClassName="active-link" to="/dashboard">
        <StyledLeftMenuItem
          color="grey"
          icon={<Icon color="black" name="dashboard" width="1.6rem" />}
          textStyle="regular"
          tx="menu.dashboard"
        />
      </NavLink>

      <NavLink activeClassName="active-link" to="/profile">
        <StyledLeftMenuItem
          color="grey"
          icon={<Icon color="black" left="-1px" name="user" size="1.9rem" />}
          marginLeft="-2px"
          textStyle="regular"
          tx="menu.profile"
        />
      </NavLink>

      <StyledLeftMenuItem
        color="grey"
        href={`${environment.OLD_GRAPHITE_URL}/designs`}
        icon={<Icon color="black" height="2rem" name="pencil" width="1.6rem" />}
        textStyle="regular"
        tx="menu.designs"
        variant="link"
      />

      {props.hasProducts && (
        <>
          <NavLink activeClassName="active-link" to="/products">
            <StyledLeftMenuItem
              color="grey"
              icon={
                <Icon
                  color="black"
                  height="2rem"
                  name="lamp"
                  top="1px"
                  width="1.6rem"
                />
              }
              textStyle="regular"
              tx="menu.products"
            />
          </NavLink>

          <NavLink activeClassName="active-link" to="/orders">
            <StyledLeftMenuItem
              color="grey"
              icon={
                <Icon
                  color="black"
                  height="2rem"
                  name="box"
                  top="1px"
                  width="1.6rem"
                />
              }
              textStyle="regular"
              tx="menu.orders"
            />
          </NavLink>

          {props.hasPayouts && (
            <NavLink activeClassName="active-link" to="/payouts">
              <StyledLeftMenuItem
                color="grey"
                icon={
                  <Icon
                    color="black"
                    height="2rem"
                    name="dollar"
                    top="1px"
                    width="1.6rem"
                  />
                }
                textStyle="regular"
                tx="menu.payouts"
              />
            </NavLink>
          )}

          <NavLink activeClassName="active-link" to="/stats">
            <StyledLeftMenuItem
              color="grey"
              icon={<Icon height="2rem" name="stats" width="1.6rem" />}
              textStyle="regular"
              tx="menu.stats"
            />
          </NavLink>
        </>
      )}

      <a
        href="https://gantri.notion.site/Creator-Documents-1f7f31f6049f4a368aa9919ff7db130c/"
        rel="noreferrer"
        target="_blank"
      >
        <StyledLeftMenuItem
          color="grey"
          icon={<Icon color="black" name="docs" width="1.6rem" />}
          textStyle="regular"
          tx="menu.docs"
        />
      </a>

      <NavLink activeClassName="active-link" to="/downloads">
        <StyledLeftMenuItem
          color="grey"
          icon={<Icon color="black" name="download" width="1.6rem" />}
          textStyle="regular"
          tx="menu.downloads"
        />
      </NavLink>
    </StackedContainer>
  </div>
);

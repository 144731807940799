import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgLiveTracking = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 48 48"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#8F8E8B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M16.982 13.826v25.043M27.899 21.653V6.001M26.34 32.161l-9.358 6.708-10.917-7.826V6l10.917 7.826L27.9 6l10.917 7.826v7.826" />
        <path d="M41.935 31.043C41.935 34.5 35.697 42 35.697 42s-6.239-7.5-6.239-10.957c0-3.458 2.792-6.26 6.24-6.26 3.445 0 6.237 2.802 6.237 6.26z" />
        <path d="M38.036 31.043a2.344 2.344 0 01-2.339 2.348 2.343 2.343 0 01-2.34-2.348 2.343 2.343 0 012.34-2.348 2.344 2.344 0 012.34 2.348z" />
      </g>
    </svg>
  );
});
export default SvgLiveTracking;

import styled from 'styled-components';
import { getThemeColor } from 'styles/theme';
import { media } from 'styles/media';
import { css } from 'styled-components/macro';

export const StyledStatsChartContainer = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${getThemeColor('lightGrey')};
    padding: ${theme.spacing.s3};
    margin-bottom: 1rem;

    ${media.lessThan('md')`
      padding: ${theme.spacing.s2};
      max-width: calc(100vw - 5rem);
    `}

    ${media.lessThan('sm')`
      padding: ${theme.spacing.s1};
      max-width: calc(100vw - 2.4rem);
    `}
  `}
`;

export const StyledStatsChart = styled.div`
  height: 40.4rem;
  max-width: 100vw;
`;

export const StyledSelectorContainer = styled.div`
  display: inline-flex;
  justify-content: center;
`;

import dayjs, { OpUnitType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration, { Duration } from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export type DATE_FORMATS =
  | 'MM/DD/YY'
  | 'MM/DD/YYYY'
  | 'MMM DD'
  | 'MMMM DD'
  | 'MMMM YYYY'
  | 'MMM DD, YYYY'
  | 'MM/DD/YYYY [at] hh:mmA'
  | 'YYYY MM DD'
  | string;

const PST_TIMEZONE = 'America/Los_Angeles';

export const formatDate = (
  date: dayjs.ConfigType,
  format: DATE_FORMATS = 'MM/DD/YYYY',
  timezone?: string,
): string => {
  if (!date) {
    return 'TBD';
  }

  if (timezone) {
    return dayjs.tz(date, timezone).format(format);
  }

  return dayjs(date).format(format);
};

export const formatPstDate = (
  date: dayjs.ConfigType,
  format: DATE_FORMATS = 'MM/DD/YYYY',
): string => formatDate(date, format, PST_TIMEZONE);

export const formatUtcDate = (
  date: dayjs.ConfigType,
  format: DATE_FORMATS = 'MM/DD/YYYY',
): string => (date ? dayjs(date).utc().format(format) : 'TBD');

export const addYears = (date: Date, years: number): Date =>
  new Date(date.setFullYear(date.getFullYear() + years));

export const getDuration = (
  from: dayjs.ConfigType,
  to: dayjs.ConfigType = new Date(),
): Duration => dayjs.duration(dayjs(to).diff(dayjs(from)));

export const toDate = (date: dayjs.ConfigType): string =>
  dayjs().to(dayjs(date));

export const toPstDate = (date: dayjs.ConfigType = new Date()): dayjs.Dayjs =>
  dayjs.tz(date, PST_TIMEZONE);

export const addToDate = (
  date: dayjs.ConfigType,
  value: number,
  unit: OpUnitType,
): dayjs.Dayjs => dayjs().add(value, unit);

export const firstDateOfMonth = (): Date => dayjs().startOf('month').toDate();

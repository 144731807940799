import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgLink = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 16 16"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.667 12.667H3.333V3.333H8V2H3.333C2.593 2 2 2.6 2 3.333v9.333C2 13.4 2.593 14 3.333 14h9.333A1.34 1.34 0 0014 12.667V8h-1.333v4.667zM9.333 2v1.333h2.393L5.173 9.887l.94.94 6.553-6.553v2.393H14V2H9.333z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgLink;

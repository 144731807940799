import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgStarOutlined = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 17 16"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.5 1.68L6.369 5.953l-4.745.682 3.433 3.312-.812 4.688L8.5 12.421l4.255 2.214-.812-4.688 3.433-3.312-4.746-.682L8.5 1.68z"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth={1.5}
      />
    </svg>
  );
});
export default SvgStarOutlined;

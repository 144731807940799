import { FC, PropsWithChildren } from 'react';
import { Pie, PieTooltipProps } from '@nivo/pie';
import { Device } from 'services/api';
import { getThemeColor, themeColors } from 'styles/theme';
import { Typography } from 'core/typography';
import { FlexContainer } from 'layout/flex-container';
import {
  StyledChartTooltipColor,
  StyledChartTooltipColorContainer,
} from '../stats-tooltip/stats-tooltip.styles';
import { StyledChartLegend } from '../../stats.styles';
import { DevicesChartPresets } from './devices-chart.presets';
import { DevicesChartProps } from './devices-chart.props';
import { getChartColor } from '../../stats.helpers';

const colors = themeColors.reverse();

export const DevicesChart: FC<DevicesChartProps> = props => {
  const { devices } = { ...DevicesChartPresets, ...props };

  return (
    <FlexContainer
      alignItems="center"
      direction="column"
      justifyContent="center"
    >
      <Pie
        activeOuterRadiusOffset={8}
        animate
        colors={({ data }) => data.color}
        data={devices.map((device: Device, index: number) => ({
          color: getChartColor(colors[index] || 'primary'),
          id: device.id,
          name: device.id,
          value: device.percent,
        }))}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        height={200}
        innerRadius={0.6}
        margin={{ bottom: 30, left: 30, right: 30, top: 30 }}
        tooltip={(
          data: PropsWithChildren<
            PieTooltipProps<{ color: string; value: number }>
          >,
        ) => (
          <StyledChartTooltipColorContainer>
            <StyledChartTooltipColor color={data.datum.color} />
            &nbsp;
            <Typography
              backgroundColor="white"
              text={`${data.datum.value || 0}% (${data.datum.id})`}
            />
          </StyledChartTooltipColorContainer>
        )}
        width={200}
      />

      <div>
        {devices.map((device: Device, index: number) => (
          <StyledChartLegend key={device.id}>
            <StyledChartTooltipColor
              color={getThemeColor(colors[index] || 'primary')}
            />
            <Typography text={`${device.percent || 0}%`} textStyle="bold" />
            <Typography color="grey" text={device.id || device.state} />
          </StyledChartLegend>
        ))}
      </div>
    </FlexContainer>
  );
};

import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

export const StyledAutocomplete = styled.div`
  position: relative;
`;

export const StyledAutocompletePopup = styled(animated.ul)`
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0;
  width: 100%;
  transform-origin: top;
  z-index: 1;
`;

export const StyledAutocompleteOption = styled.li<{ active: boolean }>`
  ${({ active, theme }) => css`
    list-style: none;
    background-color: ${theme.colors.white};
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 1rem;
    cursor: pointer;

    ${active &&
    css`
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    `}

    &:hover {
      color: ${theme.colors.primary};
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.lightGrey};
    }
  `}
`;

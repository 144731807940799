import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgAmex = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 26 18"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <rect
          fill="#F4F4F4"
          fillRule="nonzero"
          height={17}
          rx={2}
          stroke="#E4E5E6"
          width={25}
          x={0.5}
          y={0.5}
        />
        <g fill="#006ED6">
          <path d="M3.998 6.5L2 11.5h1.395l.375-1.25h1.962L6.1 11.5h1.395l-1.991-5H3.998zm.759 1.345l.661 1.78h-1.33l.669-1.78zM12.113 6.5l-1.395 1.83L9.33 6.5h-.995v5h1.159V8.675l1.155 1.575h.108l1.052-1.575V11.5h1.737v-5zM14.704 6.5v5h3.473v-1.25h-2.315v-.625h1.736v-1.25h-1.736V7.75h1.736V6.5zM23.908 11.5h-1.75l-.48-.612a12.76 12.76 0 00-.283-.375c-.174-.226-.29-.38-.345-.462-.261.38-.475.68-.64.9l-.413.549h-1.724l1.916-2.598L18.36 6.5h1.741l.31.47c.178.237.39.564.639.918.236-.376.425-.7.566-.88l.355-.508h1.708l-1.756 2.402 1.985 2.598z" />
        </g>
      </g>
    </svg>
  );
});
export default SvgAmex;

import { ApiResponse, ApisauceInstance } from 'apisauce';
import { environment } from 'config/environment';

export class ApiInstance {
  protected api: ApisauceInstance;

  protected path: string;

  constructor(apiInstance: ApisauceInstance, path: string) {
    this.api = apiInstance;
    this.path = path;
  }

  async getSingle<T>(id: string): Promise<T> {
    const response: ApiResponse<T> = await this.api.get(`${this.path}/${id}`);
    return this.handleResponse<T>(response);
  }

  async getAll<T>(): Promise<T> {
    const response: ApiResponse<T> = await this.api.get(`${this.path}`);
    return this.handleResponse<T>(response);
  }

  async put<T>(data: any, path: string = null): Promise<T> {
    const response: ApiResponse<T> = await this.api.put(
      `${path || this.path}`,
      data,
    );
    return this.handleResponse<T>(response);
  }

  protected async handleResponse<T>(response: ApiResponse<T>): Promise<T> {
    if (response.status === 401) {
      if (window.location.pathname === '/accept') {
        throw new Error('Unauthorized');
      }

      if (window.location.pathname === '/') {
        window.location.replace(
          `${environment.OLD_GRAPHITE_URL}${window.location.pathname}`,
        );
      } else {
        window.location.replace(
          `${environment.SHOP_URL}/sign-up?redirectUrl=${window.location.href}`,
        );
      }
    }

    if (!response.ok) {
      throw new Error(response?.data['error']);
    }

    return response.data;
  }
}

import styled from 'styled-components/macro';

export const StyledEditActionsContainer = styled.div`
  display: flex;
  align-items: center;
  user-select: none;

  & > p {
    cursor: pointer;

    &:not(:last-child) {
      position: relative;
      margin-right: 2rem;

      &:after {
        content: "";
        position: absolute;
        height: 1.5rem;
        width: 1px;
        background-color: ${props => props.theme.colors.lightGrey};
        right: -1rem;
        top: 4px;
        cursor: default;
      }
    }
  }
}
`;

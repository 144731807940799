import styled from 'styled-components';
import { css } from 'styled-components/macro';
import { media } from 'styles/media';

export const StyledTableContainer = styled.div`
  ${({ theme }) => css`
    display: block;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-left: 1px solid ${theme.colors.lightGrey};
    border-right: 1px solid ${theme.colors.lightGrey};

    ${media.lessThan('md')`
      max-width: calc(100vw - 2.4rem);
    `}
    table {
      border-spacing: 0;
      border-top: 1px solid ${theme.colors.lightGrey};
      border-bottom: 1px solid ${theme.colors.lightGrey};
      width: 100%;

      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      th {
        background-color: ${theme.colors.cream};
        text-align: left;
        font-weight: 400;
      }

      td {
        color: ${theme.colors.grey};
      }

      th,
      td {
        margin: 0;
        padding: ${theme.spacing.s1};
        border-bottom: 1px solid ${theme.colors.lightGrey};
        user-select: none;

        &.collapse {
          width: 0.0000000001%;
        }

        &:first-child {
          padding-left: ${theme.spacing.s2};

          ${media.lessThan('sm')`
            padding-left: ${theme.spacing.s1};
          `}
        }

        :last-child {
          border-right: 0;
          padding-right: ${theme.spacing.s2};

          ${media.lessThan('sm')`
            padding-right: ${theme.spacing.s1};
          `}
        }
      }

      tfoot {
        tr td {
          color: ${theme.colors.black};
          font-weight: 600;
          border-top: 1px solid ${theme.colors.lightGrey};
        }
      }
    }
  `}
`;

import { PageProps } from 'core/page/page.props';
import { PagePresets } from 'core/page/page.presets';
import { useEffect } from 'react';
import { BoxContainer } from 'layout/box-container';

export const Page = (props: PageProps) => {
  const { backgroundColor, children, minHeight } = {
    ...PagePresets,
    ...props,
  };

  useEffect(() => {
    const content = document.getElementById('content');
    content?.scrollTo(0, 0);
  }, []);

  return (
    <BoxContainer
      backgroundColor={backgroundColor}
      minHeight={minHeight}
      paddingBottom={{ lg: 's6', md: 's5' }}
      width="100%"
    >
      {children}
    </BoxContainer>
  );
};

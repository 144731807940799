import {
  AppSize,
  ResolutionAwareProp,
  ResolutionAwarePropBase,
} from 'types/resolution-aware-prop.type';
import { css } from 'styled-components/macro';
import { media } from 'styles/media';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import isObject from 'lodash/isObject';

const extractSizeValueFromProperty = (prop: any, size: AppSize) => {
  if (isObject(prop)) {
    switch (size) {
      case 'lg':
        return prop['lg'];
      case 'md':
        return prop['md'] ?? prop['lg'];
      case 'sm':
        return prop['sm'] ?? prop['md'] ?? prop['lg'];
    }
  }

  return prop;
};

export const extractValuesFromResolutionAwareProp = (
  prop: any,
): ResolutionAwarePropBase<any> => {
  if (Array.isArray(prop)) {
    return prop.reduce(
      (accumulator, currentProp) => ({
        ...accumulator,
        lg: [
          ...accumulator.lg,
          extractSizeValueFromProperty(currentProp, 'lg'),
        ],
        md: [
          ...accumulator.md,
          extractSizeValueFromProperty(currentProp, 'md'),
        ],
        sm: [
          ...accumulator.sm,
          extractSizeValueFromProperty(currentProp, 'sm'),
        ],
      }),
      { lg: [], md: [], sm: [] },
    );
  }

  return isObject(prop)
    ? {
        lg: prop['lg'],
        md: prop['md'] ?? prop['lg'],
        sm: prop['sm'] ?? prop['md'] ?? prop['lg'],
      }
    : { lg: prop, md: prop, sm: prop };
};

export const getResolutionPrefix = (): AppSize => {
  const windowWidth = window.innerWidth;

  if (windowWidth <= 640) {
    return 'sm';
  }
  if (windowWidth > 640 && windowWidth <= 1024) {
    return 'md';
  }
};

export const extractValueFromResolutionAwareProp = (prop, size: AppSize) => {
  if (isObject(prop)) {
    switch (size) {
      case 'lg':
        return prop['lg'];
      case 'md':
        return prop['md'] ?? prop['lg'];
      default:
        return prop['sm'] ?? prop['md'] ?? prop['lg'];
    }
  }

  return prop;
};

export const calculateDimensionsFromAspectRatio = (
  aspectRatio: string,
): { height: string; width: string } => {
  const parts = aspectRatio.split(')');
  const unit = parts.length === 2 ? parts[1] : '%';
  const aux = parts[0].replace('(', '').split(':');

  return {
    height: `${(+aux[1] * 100) / +aux[0]}${unit}`,
    width: `100${unit}`,
  };
};

export const generateStylesForResolutionAwareProps = (
  entries: ExtractStyleFromResolutionAwarePropEntry[],
) => {
  let lgCss = ``;
  let mdCss = ``;
  let smCss = ``;

  entries.forEach((entry: ExtractStyleFromResolutionAwarePropEntry) => {
    if (entry.lookIn && Array.isArray(entry.resolutionAwareProp)) {
      throw new Error(
        'Specifying lookIn property when resolutionAwareProp is an array is not allowed',
      );
    }

    const { lg, md, sm } = extractValuesFromResolutionAwareProp(
      entry.resolutionAwareProp,
    );

    if (lg !== undefined) {
      const value = entry.lookIn ? entry.lookIn[lg] || lg : lg;
      lgCss += `${entry.cssProperty}: ${
        entry.valueFormatter ? entry.valueFormatter(value, 'lg') : value
      }  ${entry.isImportant ? '!important' : ''};;`;
    }

    if (md !== undefined) {
      const value = entry.lookIn ? entry.lookIn[md] || md : md;
      mdCss += `${entry.cssProperty}: ${
        entry.valueFormatter ? entry.valueFormatter(value, 'md') : value
      }  ${entry.isImportant ? '!important' : ''};;`;
    }

    if (sm !== undefined) {
      const value = entry.lookIn ? entry.lookIn[sm] || sm : sm;
      smCss += `${entry.cssProperty}: ${
        entry.valueFormatter ? entry.valueFormatter(value, 'sm') : value
      } ${entry.isImportant ? '!important' : ''};`;
    }
  });

  // prettier-ignore
  return css`
    ${lgCss}

    ${mdCss && media.lessThan("md")`
       ${mdCss}
    `}

    ${smCss && media.lessThan("sm")`
       ${smCss}
    `}
  `
};

export const isLargeScreen = () => window.innerWidth > 1024;

export const isSmallScreen = () => window.innerWidth <= 641;

export const extractValueFromResolutionAwarePropertyForCurrentScreenSize = (
  property: ResolutionAwareProp<any>,
) => {
  const size = isLargeScreen() ? 'lg' : isSmallScreen() ? 'sm' : 'md';
  return extractSizeValueFromProperty(property, size);
};

export const getAspectRatioByCategory = (category: string): string => {
  switch (category) {
    case 'floor':
    case 'Floor':
    case 'Floor Light':
      return '1:1.25';
    default:
      return '1:1';
  }
};

export const isCssPropertyValueDefined = (property: string) =>
  property && property !== 'none' && property !== 'unset';

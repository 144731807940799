import { useEffect, useRef, useState } from 'react';
import { TooltipProps } from 'core/tooltip/tooltip.props';
import { TooltipPresets } from 'core/tooltip/tooltip.presets';
import {
  StyledTooltip,
  StyledTooltipContent,
} from 'core/tooltip/tooltip.styles';
import { useSpring } from 'react-spring';
import * as easings from 'd3-ease';

export const Tooltip = (props: TooltipProps) => {
  const {
    children,
    content,
    popupWidth,
    position,
    roundContainer,
    visibleOnMobile,
  } = { ...TooltipPresets, ...props };
  const [visible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>();
  const popupRef = useRef<HTMLDivElement>();
  const verticalBuffer = 6;
  const horizontalBuffer = 40;

  const { scale } = useSpring({
    config: {
      duration: 10,
      easing: easings.easeCubicInOut,
    },
    scale: visible ? 1 : 0,
  });

  const hideTooltip = () => setVisible(false);

  useEffect(() => {
    if (visible) {
      window?.addEventListener('wheel', hideTooltip);
    } else {
      window?.removeEventListener('wheel', hideTooltip);
    }
  }, [visible]);

  const setPopupPosition = (): void => {
    const { height, left, top } = ref.current.getBoundingClientRect();

    switch (position) {
      case 'bottom':
        popupRef.current.style.top = `${top + height + verticalBuffer}px`;
        break;
      case 'top':
      default:
        popupRef.current.style.top = `${
          top - popupRef.current.clientHeight - verticalBuffer
        }px`;
        break;
    }

    const distanceToRight = window.innerWidth - left;

    if (distanceToRight + horizontalBuffer < popupRef.current.clientWidth) {
      popupRef.current.style.left = `${
        window.innerWidth - popupRef.current.clientWidth - horizontalBuffer
      }px`;
    } else {
      popupRef.current.style.left = `${left - horizontalBuffer}px`;
    }
  };

  const showTooltip = (): void => {
    setPopupPosition();
    setVisible(true);
  };

  return (
    <StyledTooltip
      ref={ref}
      roundContainer={roundContainer}
      onClick={event => event.preventDefault()}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}

      <StyledTooltipContent
        ref={popupRef}
        style={{ scale }}
        visibleOnMobile={visibleOnMobile}
        width={popupWidth}
      >
        {content}
      </StyledTooltipContent>
    </StyledTooltip>
  );
};

import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgApplePay = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 10 13"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.54 2.045c.407-.55.683-1.29.61-2.045-.596.032-1.324.425-1.745.976-.378.472-.713 1.242-.626 1.966.67.063 1.338-.361 1.76-.897m.604 1.038c-.973-.062-1.799.597-2.263.597-.465 0-1.176-.565-1.945-.55-1 .016-1.93.628-2.437 1.6-1.044 1.946-.275 4.832.74 6.416.494.784 1.088 1.647 1.871 1.616.74-.031 1.03-.517 1.93-.517.9 0 1.16.517 1.944.502.813-.016 1.32-.785 1.814-1.57.566-.893.798-1.756.812-1.803-.014-.016-1.567-.66-1.581-2.589-.015-1.615 1.219-2.384 1.277-2.432-.697-1.113-1.785-1.238-2.162-1.27"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgApplePay;

import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgMenu = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 19 9"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        opacity={0.5}
        stroke={color}
        strokeWidth={2}
      >
        <path d="M0 1h19M0 8h19" />
      </g>
    </svg>
  );
});
export default SvgMenu;

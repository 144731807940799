import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { media } from 'styles/media';

export const StyledThankYouCard = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.lightGrey};
    padding: ${theme.spacing.s3} ${theme.spacing.s2};
    margin-top: ${theme.spacing.s2};
    max-width: 29rem;
    overflow: hidden;

    ${media.lessThan('md')`
      max-width: unset;
    `}
  `}
`;

import styled from 'styled-components/macro';
import { css } from 'styled-components';

export const StyledHeading = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr) max-content;
    margin-bottom: ${theme.spacing.s5};
  `}
`;

export const StyledHeadingElement = styled.div`
  ${({ theme }) => css`
    cursor: pointer;

    &:not(:last-child) {
      border-right: 1px solid ${theme.colors.lightGrey};
    }

    &:not(:first-child) {
      padding-left: ${theme.spacing.s2};
    }
  `}
`;

import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgCamera = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 54 38"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke={color} strokeWidth={2}>
        <path d="M35.182 20.636a8.183 8.183 0 11-16.366 0 8.183 8.183 0 0116.366 0z" />
        <path
          d="M38.455 7.545h9.544c2.475 0 4.5 2.026 4.5 4.5V32.5c0 2.475-2.025 4.5-4.5 4.5H6a4.513 4.513 0 01-4.5-4.5V12.045c0-2.474 2.025-4.5 4.5-4.5h9.546M15.545 7.545L22.091 1h9.818l6.546 6.545"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
});
export default SvgCamera;

import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { media } from 'styles/media';

export const StyledShipment = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.lightGrey};
    padding: ${theme.spacing.s3};
    border-radius: 0.6rem;
  `}
`;

export const StyledShipmentStock = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    column-gap: ${theme.spacing.s2};

    ${media.lessThan('sm')`
      grid-template-columns: max-content 1fr;
    `}
  `}
`;

export const StyledProgressInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
  gap: 0.6rem;
`;

export const StyledRefundReason = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.lightGrey};
    padding-top: ${theme.spacing.s2};
    margin-top: ${theme.spacing.s2};

    ${media.lessThan('sm')`
      grid-template-columns: max-content 1fr;
      padding-top: ${theme.spacing.s1};
      margin-top: ${theme.spacing.s1};
    `}
  `}
`;

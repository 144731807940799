import { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'styles/base';
import { theme } from 'styles/theme';
import { BrowserRouter } from 'react-router-dom';
import { LoadingOverlay } from 'layout/loading-overlay';
import { ModalProvider } from 'context/modal.context';
import { BaseLayout } from './layout';
import { AppProviders } from './app-providers';
import { AppRoutes } from './app-routes';

import './i18n';

const App = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Suspense fallback={<LoadingOverlay />}>
      <AppProviders>
        <BrowserRouter>
          <ModalProvider>
            <BaseLayout>
              <AppRoutes />
            </BaseLayout>
          </ModalProvider>
        </BrowserRouter>
      </AppProviders>
    </Suspense>
  </ThemeProvider>
);

export default App;

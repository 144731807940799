import styled, { css } from 'styled-components';
import { media } from 'styles/media';
import PoweredByGoogleImage from '../../assets/images/common/powered-by-google.png';

export const StyledPoweredByGoogle = styled.div`
  ${({ theme }) => css`
    background-image: url(${PoweredByGoogleImage});
    background-position: calc(100% - 2rem) center;
    background-size: 15rem auto;
    background-repeat: no-repeat;
    height: 4.1rem;
    background-color: ${theme.colors.paper};
    padding-right: ${theme.spacing.s3};

    ${media.lessThan('sm')`
        background-position: calc(100% - 1.2rem) center;
    `}
  `}
`;

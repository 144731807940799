import styled, { css } from 'styled-components';
import { TextInputProps } from 'core/text-input/text-input.props';
import { getThemeColor, getThemeSpacing } from 'styles/theme';
import { Typography } from '../typography';

export const StyledInputContainer = styled.div``;

export const StyledInput = styled.input<
  TextInputProps & { invalid?: boolean; touched?: boolean }
>`
  ${({ invalid, paddingLeft, theme, touched, type, variant }) => {
    let color;
    const markAsInvalid = invalid && touched;

    if (markAsInvalid) {
      color = getThemeColor('red');
    } else {
      color = variant === 'default' ? theme.colors.black : theme.colors.white;
    }

    return css`
      width: 100%;
      min-width: 100%;
      border: none;
      border-bottom: 1px solid ${markAsInvalid ? color : theme.colors.lightGrey};
      border-radius: 0;
      padding: 0 2rem 1rem 0;
      padding-left: ${getThemeSpacing(paddingLeft)};
      outline: none;
      color: ${color};
      font-size: 1.5rem;
      line-height: 2rem;
      height: 3rem;
      letter-spacing: ${type === 'password' ? '2px' : 0};
      background-color: transparent;
      transition: all ${theme.transitions.base};
    `;
  }}
`;

export const StyledInputContent = styled.div<{
  focused?: boolean;
  invalid?: boolean;
  touched?: boolean;
  variant?: 'default' | 'dark';
}>`
  ${({ focused, invalid, theme, touched, variant }) => {
    let backgroundColor;
    const markAsInvalid = invalid && touched;

    if (markAsInvalid) {
      backgroundColor = getThemeColor('red');
    } else {
      backgroundColor =
        variant === 'default' ? theme.colors.black : theme.colors.white;
    }

    return css`
      position: relative;
      flex: 1;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 1px;
        transform: scale(0);
        width: 100%;
        background-color: ${backgroundColor};

        ${focused &&
        css`
          transform: scale(1);
        `}
      }
    `;
  }}
`;

export const StyledBodyContainer = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
`;

export const StyledPlaceholder = styled(Typography)`
  position: absolute;
  top: 0;
  pointer-events: none;
`;

export const StyledIconContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 5px;
`;

export const StyleClearableIconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

export const StyledInputActionContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const StyledInputDescription = styled(Typography)`
  position: relative;
  top: -5px;
`;

export const StyledPrefixContainer = styled.div`
  position: absolute;
  top: 0;
`;

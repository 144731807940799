import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgCalendar = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 20 20"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path d="M0 20h20V0H0z" id="calendar_svg__a" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M4.13 2.566H1V19h18V2.566h-3.13"
          stroke={color}
          strokeLinejoin="round"
        />
        <mask fill="#fff" id="calendar_svg__b">
          <use xlinkHref="#calendar_svg__a" />
        </mask>
        <path
          d="M4.131 4.13h2.348V1H4.131zM13.521 4.13h2.348V1h-2.348z"
          mask="url(#calendar_svg__b)"
          stroke={color}
          strokeLinejoin="round"
        />
        <path
          d="M6.479 2.566h7.042M1 6.479h18"
          stroke={color}
          strokeLinejoin="round"
        />
        <path
          d="M5.695 8.043v9.392M10 8.043v9.392M14.305 8.043v9.392M2.566 9.608h14.868M2.566 12.739h14.868M2.566 15.869h14.868"
          stroke={color}
          strokeLinejoin="round"
          strokeWidth={0.5}
        />
      </g>
    </svg>
  );
});
export default SvgCalendar;

import { useRef, memo } from 'react';
import { getThemeColor } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgEmailRound = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 80 80"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M59 51.323c0 1.705-1.216 3.088-2.714 3.088H23.714c-1.498 0-2.714-1.383-2.714-3.088V28.677c0-1.705 1.216-3.088 2.714-3.088h32.572c1.498 0 2.714 1.383 2.714 3.088v22.646z" />
        <path d="M53 31L39.5 43 26 31" strokeLinecap="round" />
        <path
          d="M78 40c0 20.987-17.013 38-38 38S2 60.987 2 40 19.013 2 40 2s38 17.013 38 38z"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
});
export default SvgEmailRound;

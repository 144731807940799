import { CSSProperties, FC, useMemo, useState } from 'react';
import { getThemeColor, themeColors } from 'styles/theme';
import { formatCurrency } from 'utils/formaters';
import { FlexContainer } from 'layout/flex-container';
import { Typography } from 'core/typography';
import { ButtonToggleGroup } from 'core/button-toggle-group';
import { ButtonToggle } from 'core/button-toggle';
import { ResponsiveBar } from '@nivo/bar';
import { formatPstDate } from 'utils/date';
import { useMediaLarge } from 'utils/hooks';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { BoxContainer } from 'layout/box-container';
import { Theme } from '@nivo/core';
import { StatsTooltip } from '../stats-tooltip';
import {
  StyledSelectorContainer,
  StyledStatsChart,
  StyledStatsChartContainer,
} from './stats-chart.styles';
import { StatsChartPresets } from './stats-chart.presets';
import { ChartOption, StatsChartProps } from './stats-chart.types';
import { DATE_FORMAT_BY_GROUP } from '../../stats.constants';
import { getChartColor } from '../../stats.helpers';

const nivoTheme: Theme = {
  fontSize: 12,
  textColor: getThemeColor('grey'),
  tooltip: {
    container: {
      backgroundColor: 'transparent',
      padding: 0,
    } as CSSProperties,
  },
};

const normalizeData = (
  data: any[],
  itemsName: string[],
  option: ChartOption,
) => {
  if (data && data.length) {
    return data.map((item: any) =>
      itemsName?.reduce(
        (accum: { date: number }, current: string, index: number) => {
          const skuData = item[current] || {};

          const value = !!option.valueProperty
            ? (skuData[option.valueProperty] || 0) / 100
            : skuData.count || 0;

          return {
            ...accum,
            [current]: value,
            [`${current}Color`]: skuData.color || themeColors[index],
            [`${current}Tooltip`]: skuData.fullSkuName || current,
          };
        },
        {
          date: +item.date,
        },
      ),
    );
  }

  return [];
};

export const StatsChart: FC<StatsChartProps> = props => {
  const { data, dateRange, group, itemsName, options } = {
    ...StatsChartPresets,
    ...props,
  };

  const [selectedTable, setSelectedTable] = useState(options[0].value);

  const selectedOption = useMemo(
    () =>
      options.find(
        option => (option.valueProperty || option.value) === selectedTable,
      ),
    [options, selectedTable],
  );

  const large = useMediaLarge();

  const activeChartData = useMemo(() => {
    const optionData = data[selectedOption?.value];

    if (optionData?.details) {
      return normalizeData(optionData?.details, itemsName, selectedOption);
    }
    return [];
  }, [data, itemsName, selectedOption]);

  const total = useMemo(() => {
    const optionData = data[selectedOption.value];

    if (optionData) {
      if (selectedOption.totalProperty) {
        return formatCurrency(optionData[selectedOption.totalProperty]);
      }
      return optionData.totalCount.toLocaleString();
    }

    return 0;
  }, [data, selectedOption]);

  return (
    <StyledStatsChartContainer>
      <Grid
        columns={{ lg: 'repeat(2, max-content)', md: '1fr' }}
        justifyContent={{ lg: 'space-between', md: 'stretch' }}
        justifyItems={{ lg: 'center', md: 'stretch' }}
        rowGap="s2"
      >
        <Cell row={{ lg: 1, md: 2 }}>
          <FlexContainer
            alignItems="center"
            justifyContent="space-between"
            marginBottom="20px"
          >
            <Typography
              text={`${selectedOption.text}: ${total}`}
              textStyle="bold"
              variant="h4"
            />

            <BoxContainer hidden={{ lg: true, md: false }}>
              <Typography color="grey" text={dateRange} variant="p3" />
            </BoxContainer>
          </FlexContainer>
        </Cell>

        {options.length > 1 && (
          <Cell>
            <StyledSelectorContainer>
              <ButtonToggleGroup
                backgroundColor="cream"
                containerPadding="2px"
                itemsGap="1rem"
                selectedBackgroundColor="white"
                selectedTextColor="black"
                value={selectedTable}
                onChange={setSelectedTable}
              >
                {options.map(option => (
                  <ButtonToggle
                    key={option.valueProperty || option.value}
                    text={option.text}
                    value={option.valueProperty || option.value}
                  />
                ))}
              </ButtonToggleGroup>
            </StyledSelectorContainer>
          </Cell>
        )}
      </Grid>

      <StyledStatsChart>
        {/* @ts-ignore Component is not yet fully typed. April 08, 2022 */}
        <ResponsiveBar
          animate
          axisBottom={
            large && {
              format: event =>
                formatPstDate(event, DATE_FORMAT_BY_GROUP[group]).toUpperCase(),
              tickPadding: 10,
              tickSize: 0,
            }
          }
          axisLeft={
            large && {
              format: (element: number) => {
                if (selectedOption.type === 'currency') {
                  return formatCurrency(element * 100);
                }

                return (
                  Math.floor(element) === element && element.toLocaleString()
                );
              },
              tickPadding: 5,
              tickSize: 0,
            }
          }
          axisRight={null}
          axisTop={null}
          colors={({ data, id }) => getChartColor(data[`${id}Color`])}
          data={activeChartData}
          enableLabel={false}
          indexBy="date"
          indexScale={{
            round: true,
            type: 'band',
          }}
          keys={itemsName}
          labelSkipHeight={12}
          labelSkipWidth={12}
          margin={
            large && {
              bottom: 25,
              left: 12 + (total.length - 1) * 5,
              right: 0,
              top: 25,
            }
          }
          motionDamping={15}
          motionStiffness={90}
          padding={0.3}
          theme={nivoTheme}
          tooltip={props => (
            <StatsTooltip
              {...props}
              group={group}
              type={selectedOption && selectedOption.type}
            />
          )}
          valueScale={{ type: 'linear' }}
        />
      </StyledStatsChart>
    </StyledStatsChartContainer>
  );
};

import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { media } from 'styles/media';

export const StyledPhoto = styled.div`
  ${({ theme }) => css`
    position: relative;

    &:hover ${StyledActions} {
      display: flex;
    }
  `}
`;

export const StyledNewPhoto = styled.div`
  ${({ theme }) => css`
    border: 1px dashed ${theme.colors.lightGrey};
    width: 20rem;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    ${media.lessThan('sm')`
      width: 11rem;
      height: 11rem;
    `}
  `}
`;

export const StyledActions = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: none;
  `}
`;

export const StyledAction = styled.div`
  ${({ theme }) => css`
    width: 3.2rem;
    height: 3.2rem;
    background-color: ${theme.colors.white};
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.colors.cream};
  `}
`;

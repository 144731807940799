import styled, { css } from 'styled-components/macro';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { getThemeColor, getThemeSpacing } from 'styles/theme';
import { GridProps } from './grid.props';

const templateColumnsFormatter = value =>
  isNaN(value) ? value : `repeat(${value}, 1fr);`;

const templateRowsFormatter = value =>
  isNaN(value) ? value : `repeat(${value}, max-content);`;

export const StyledGrid = styled.div<GridProps>`
  ${({
    width,
    height,
    columns,
    autoColumns,
    rows,
    autoRows,
    flow,
    gap,
    columnGap = gap,
    rowGap = gap,
    padding,
    horizontalPadding = padding,
    paddingLeft = horizontalPadding,
    paddingRight = horizontalPadding,
    verticalPadding = padding,
    paddingTop = verticalPadding,
    paddingBottom = verticalPadding,
    margin,
    horizontalMargin = margin,
    marginLeft = horizontalMargin,
    marginRight = horizontalMargin,
    verticalMargin = margin,
    marginTop = verticalMargin,
    marginBottom = verticalMargin,
    justifyContent,
    alignContent,
    justifyItems,
    justifySelf,
    alignItems,
    hidden,
    maxWidth,
    minWidth,
    minHeight,
    maxHeight,
    backgroundColor,
    border,
    borderRadius,
  }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'grid-auto-flow',
        resolutionAwareProp: flow,
      },
      {
        cssProperty: 'grid-auto-columns',
        resolutionAwareProp: autoColumns,
      },
      {
        cssProperty: 'grid-auto-rows',
        resolutionAwareProp: autoRows,
      },
      {
        cssProperty: 'grid-template-columns',
        resolutionAwareProp: columns,
        valueFormatter: templateColumnsFormatter,
      },
      {
        cssProperty: 'grid-template-rows',
        resolutionAwareProp: rows,
        valueFormatter: templateRowsFormatter,
      },
      {
        cssProperty: 'grid-column-gap',
        resolutionAwareProp: columnGap,
        valueFormatter: getThemeSpacing,
      },
      {
        cssProperty: 'grid-row-gap',
        resolutionAwareProp: rowGap,
        valueFormatter: getThemeSpacing,
      },
      {
        cssProperty: 'padding-left',
        resolutionAwareProp: paddingLeft,
        valueFormatter: getThemeSpacing,
      },
      {
        cssProperty: 'padding-right',
        resolutionAwareProp: paddingRight,
        valueFormatter: getThemeSpacing,
      },
      {
        cssProperty: 'padding-top',
        resolutionAwareProp: paddingTop,
        valueFormatter: getThemeSpacing,
      },
      {
        cssProperty: 'padding-bottom',
        resolutionAwareProp: paddingBottom,
        valueFormatter: getThemeSpacing,
      },
      {
        cssProperty: 'margin-left',
        resolutionAwareProp: marginLeft,
        valueFormatter: getThemeSpacing,
      },
      {
        cssProperty: 'margin-right',
        resolutionAwareProp: marginRight,
        valueFormatter: getThemeSpacing,
      },
      {
        cssProperty: 'margin-top',
        resolutionAwareProp: marginTop,
        valueFormatter: getThemeSpacing,
      },
      {
        cssProperty: 'margin-bottom',
        resolutionAwareProp: marginBottom,
        valueFormatter: getThemeSpacing,
      },
      {
        cssProperty: 'align-content',
        resolutionAwareProp: alignContent,
      },
      {
        cssProperty: 'justify-content',
        resolutionAwareProp: justifyContent,
      },
      {
        cssProperty: 'align-items',
        resolutionAwareProp: alignItems,
      },
      {
        cssProperty: 'justify-items',
        resolutionAwareProp: justifyItems,
      },
      {
        cssProperty: 'justify-self',
        resolutionAwareProp: justifySelf,
      },
      {
        cssProperty: 'display',
        resolutionAwareProp: hidden,
        valueFormatter: value => (value === true ? 'none' : 'grid'),
      },
      {
        cssProperty: 'max-width',
        resolutionAwareProp: maxWidth,
      },
      {
        cssProperty: 'min-width',
        resolutionAwareProp: minWidth,
      },
      {
        cssProperty: 'min-height',
        resolutionAwareProp: minHeight,
      },
      {
        cssProperty: 'max-height',
        resolutionAwareProp: maxHeight,
      },
      {
        cssProperty: 'background-color',
        resolutionAwareProp: backgroundColor,
        valueFormatter: value => getThemeColor(value),
      },
      {
        cssProperty: 'width',
        resolutionAwareProp: width,
      },
      {
        cssProperty: 'height',
        resolutionAwareProp: height,
      },
      {
        cssProperty: 'border-radius',
        resolutionAwareProp: borderRadius,
      },
    ];

    return css`
      display: grid;
      position: relative;
      border: ${border || 'unset'};
      ${generateStylesForResolutionAwareProps(entries)};
    `;
  }}
`;
